
<div *ngIf="isPageLoad; then thenBlock else elseBlock"></div>
<ng-template #thenBlock style="text-align:center">
  <ng-template #template>
    <div class="modal-body text-center">
      <p>{{message}}</p>
      <button type="button" class="btn btn-default" (click)="confirm(errortemplate)">Yes</button>
      <button type="button" class="btn btn-primary" (click)="decline()">No</button>
    </div>
  </ng-template>

  <ng-template #errortemplate>
    <div class="modal-header">
      <h5 class="modal-title pull-left" id="my-modal-title">Attention</h5>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div id="my-modal-description">
        {{errorMessage}}
      </div>
    </div>
  </ng-template>
<!--
  <div class="center" id='onlineUser'>
    {{researcher.name}}
  </div>
-->
  <mat-card class="box">

    <h6>{{researcher.name}}</h6>

     <input *ngIf="isUpdate == false" type="file" id="fileInput" name="files" (change)="openFileModal(template, errortemplate, $event)"/>

      <form class="example-form" [formGroup]="settingsForm" (ngSubmit)="setGame(errortemplate)">
          <mat-form-field style="padding: 5px !important; width: 300px !important;"  class="example-full-width" 
          hintLabel={{systemService.languageItems[systemService.systemLanguage].targetOrderProj}}>
              <mat-select [(value)]="targetOrderSelected">
                <mat-option value="random">
                  {{systemService.languageItems[systemService.systemLanguage].randomChoice}}
                </mat-option>
                <mat-option value="order">
                  {{systemService.languageItems[systemService.systemLanguage].orderChoice}}
                </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field style="padding: 5px !important; width: 300px !important;"  class="example-full-width" 
          hintLabel={{systemService.languageItems[systemService.systemLanguage].contextOrderProj}}>
            <mat-select [(value)]="contextOrderSelected">
                <mat-option value="random">
                  {{systemService.languageItems[systemService.systemLanguage].randomChoice}}
                </mat-option>
                <mat-option value="order">
                  {{systemService.languageItems[systemService.systemLanguage].orderChoice}}
                </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="padding: 5px !important; width: 300px !important;"  class="example-full-width"
          hintLabel={{systemService.languageItems[systemService.systemLanguage].contextNumberProj}}>
            <mat-select [(value)]="contextNumberSelected">
              <mat-option value="4">4</mat-option>
              <mat-option value="8">8</mat-option>
              <mat-option value="12">12</mat-option>
              <mat-option value="16">16</mat-option>
              <mat-option value="20">20</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field style="padding: 5px !important; width: 300px !important;"  class="example-full-width" 
          hintLabel={{systemService.languageItems[systemService.systemLanguage].extraCueProj}}>
              <mat-select [(value)]="hintOrderSelected">
                <mat-option value="random">
                  {{systemService.languageItems[systemService.systemLanguage].randomChoice}}
                </mat-option>
                <mat-option value="highest">
                  {{systemService.languageItems[systemService.systemLanguage].highestChoice}}
                </mat-option>
                <mat-option value="lowest">
                  {{systemService.languageItems[systemService.systemLanguage].lowestChoice}}
                </mat-option>
                <mat-option value="none">
                  {{systemService.languageItems[systemService.systemLanguage].noneChoice}}
                </mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field style="padding: 5px !important; width: 300px !important;"  class="example-full-width" 
          class="example-full-width" hintLabel={{systemService.languageItems[systemService.systemLanguage].gameDescProj}}>
          <mat-label>{{systemService.languageItems[systemService.systemLanguage].gameLabelProj}}</mat-label>
          <textarea matInput
            formControlName="descriptionControl"
            id="gameDescription"
            placeholder="Language, extra cue ...">
          </textarea>
          </mat-form-field>
          <br>
          <button *ngIf="isUpdate == false && systemService.systemLanguage=='de'" mat-flat-button color="primary">Spiel Festlegen</button>
          <button *ngIf="isUpdate == false && systemService.systemLanguage=='en'" mat-flat-button color="primary">Set Game</button>
      </form>
    <button *ngIf="isUpdate == true" mat-flat-button color="primary" (click)="updateGame(errortemplate)">
    {{systemService.languageItems[systemService.systemLanguage].updateGameProj}}
    </button>
    &nbsp;
    <button *ngIf="isUpdate == true" mat-flat-button color="primary" (click)="exportGame(errortemplate)">
    {{systemService.languageItems[systemService.systemLanguage].exportDataProj}}
    </button>
  </mat-card>
</ng-template>
<ng-template class="center" #elseBlock>You should login as a researcher for this page!</ng-template>
