<ng-template #template>
  <div class="modal-body text-left">
    <p>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalGender}}: {{modalShowPlayer.gender[systemService.systemLanguage]}}</p>
    <p>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalAge}}: {{modalShowPlayer.age[systemService.systemLanguage]}}</p>
    <p>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalLanguage}}: {{modalShowPlayer.nativeLanguage[systemService.systemLanguage]}}</p>
    <p>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].confirmQuestion}}</p>
    <button type="button" class="btn btn-default" (click)="confirm()">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalYesButton}}
    </button>
    <button type="button" class="btn btn-primary" (click)="decline()">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalNoButton}}
    </button>
  </div>
</ng-template>

<ng-template #errortemplate>
  <div class="modal-header">
    <h6 class="modal-title pull-left" id="my-modal-title">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalAttention}}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="my-modal-description">
      {{errorMessage}}
    </div>
  </div>
</ng-template>

<div *ngIf="isUpdate; then thenBlock else elseBlock"></div>

    <ng-template #elseBlock >
      <mat-card style="text-align:left">
      <h5 class="card-title">{{player.name}}</h5>
      <h6 class="card-subtitle mb-2 text-muted">{{player.date}}</h6>
      <p class="card-text">
        {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalGender}}: {{modalPlayer.gender[systemService.systemLanguage]}}</p>
      <p class="card-text">
        {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalAge}}: {{modalPlayer.age[systemService.systemLanguage]}}</p>
      <p class="card-text">
        {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalLanguage}}: {{modalPlayer.nativeLanguage[systemService.systemLanguage]}}</p>


      <div class="button">
        <button (click) ="update()" mat-raised-button color="primary">
          {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].updateButton}}
        </button>
      </div>
      <br>
      <button style="font-size: 12px !important;" (click) ="deletePlayer(template, errortemplate)" mat-raised-button color="primary">
        {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].deleteButton}}
      </button>
      &nbsp;
      <button style="font-size: 12px !important;"
        (click)="back()"
        mat-raised-button color="primary">
        {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].backButton}}
      </button>
      </mat-card>
    </ng-template>

    <ng-template #thenBlock >
        <mat-card style="text-align:left">
          <form class="example-form" [formGroup]="registerationForm" (ngSubmit)="updatePlayer(template, errortemplate, registerationForm.value)">
            <mat-card-content>
              <mat-form-field  [disabled]="true" [readonly]="true" class="example-full-width">
                <input [readonly]="true" matInput placeholder="Player name" formControlName="inputPlayerName">
              </mat-form-field>
              <br>
              <mat-form-field class="example-full-width">
                <mat-label>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].changeYourGender}}</mat-label>
                <mat-select [(value)]="selectedGender" formControlName="inputPlayerGender">
                  <mat-option [value]="gender" *ngFor="let gender of systemService.Genders">
                    {{gender[systemService.systemLanguage]}}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>mode_edit</mat-icon>
              </mat-form-field>
              <br>
              <mat-form-field class="example-full-width">
                <mat-label>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].changeYourAge}}</mat-label>
                <mat-select [(value)]="selectedAge" formControlName="inputPlayerAge">
                  <mat-option [value]="age" *ngFor="let age of systemService.Ages">
                    {{age[systemService.systemLanguage]}}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>mode_edit</mat-icon>
              </mat-form-field>
              <br>
              <mat-form-field class="example-full-width">
                <mat-label>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].changeYourNativeLanguage}}</mat-label>
                <mat-select [(value)]="selectedNativeLanguage" formControlName="inputPlayerNativeLanguage">
                  <mat-option [value]="nlang" *ngFor="let nlang of nativeLanguageArray">
                    {{nlang[systemService.systemLanguage]}}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>mode_edit</mat-icon>
              </mat-form-field>
            </mat-card-content>

            <!--<button id="registerButton" type="submit" ="update()" mat-raised-button color="primary">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].updateButton}}
            </button>
            <button (click) ="back()" mat-raised-button color="primary">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].deleteButton}}
            </button>-->

            <!-- -->
            <button id="registerButton" type="submit"  mat-raised-button color="primary">
                {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].updateButton}}
            </button>
         </form>
         <button style="font-size: 12px !important;" (click) ="deletePlayer(template, errortemplate)" mat-raised-button color="primary">
           {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].deleteButton}}
         </button>
         &nbsp;
         <button style="font-size: 12px !important;"
           (click)="back()"
           mat-raised-button color="primary">
           {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].backButton}}
         </button>
      </mat-card>
    </ng-template>


<div class="collapse" id="collapseExample">
  <div class="card card-body">
    <table mat-table [dataSource]="gameDataSource"
          class="mat-elevation-z8"
          style="background-color: ghostwhite">
        <!-- Position Column -->
        <ng-container matColumnDef="target">
          <th mat-header-cell *matHeaderCellDef> {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameTarget}}</th>
          <td mat-cell *matCellDef="let element"> {{element.target}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="score">
          <th mat-header-cell *matHeaderCellDef> {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameScore}} </th>
          <td mat-cell *matCellDef="let element"> {{element.score}} </td>
        </ng-container>

    <tr mat-header-row *matHeaderRowDef="gameDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: gameDisplayedColumns;"></tr>
  </table>
</div>
</div>

<mat-card style='text-align:center'>
  <mat-table #table  [dataSource]="dataSource"
      class="mat-elevation-z8"
      matSort matSortActive="date"
      matSortDirection="asc">
      <!-- Position Column   ['gameName', 'totalScore', 'date'];-->
      <ng-container matColumnDef="gameName">
        <mat-header-cell *matHeaderCellDef> {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameDecisionLabel}} </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <a class="mat-button"
            (click) ="getActionsGame(element)"
             data-toggle="collapse"
             href="#collapseExample"
             role="button"
             aria-expanded="false"
             aria-controls="collapseExample">
              {{element.gameName}}
          </a>
         </mat-cell>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="totalScore">
        <mat-header-cell *matHeaderCellDef> {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameScore}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.totalScore}} </mat-cell>
      </ng-container>
      <!-- Weight Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].date}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  <mat-paginator [pageSizeOptions]="[5,10,20]" showFirstLastButtons>
  </mat-paginator>
</mat-card>
