<!--
Admin create researchers, or deactivate them
-->
<ng-template #errortemplate>
  <div class="modal-header">
    <h6 class="modal-title pull-left" id="my-modal-title">Attention</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="my-modal-description">
      {{errorMessage}}
    </div>
  </div>
</ng-template>

<mat-card style='text-align:center'>
  <form [formGroup]="researcherForm" (ngSubmit)="openModal(template,errortemplate)">
    <div class="form-group p-2">
      <!--
      <mat-form-field hintLabel="Enter max 10 characters">
        <input matInput
        formControlName="researcherNameControl"
        maxlength="15"
        placeholder="Project name"
        id="researcherName">
      </mat-form-field>
      -->
      <mat-form-field>
        <mat-label>
          {{systemService.languageItems[systemService.systemLanguage].language}}
        </mat-label>
        <mat-select [(value)]="selectedGameLanguage"
          formControlName="inputGameLanguage">
          <mat-option [value]="gameLanguage" *ngFor="let gameLanguage of systemService.inputGameLanguages">
            {{gameLanguage[systemService.systemLanguage]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field>
        <mat-label>
          {{systemService.languageItems[systemService.systemLanguage].wordClass}}
        </mat-label>
        <mat-select [(value)]="selectedGameClass"
          formControlName="inputGameClass">
          <mat-option [value]="gameClass" *ngFor="let gameClass of systemService.inputGameClasses">
            {{gameClass[systemService.systemLanguage]}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      &nbsp;
      <mat-form-field>
        <mat-label>
          {{systemService.languageItems[systemService.systemLanguage].gameCase}}
        </mat-label>
        <mat-select [(value)]="selectedGameCase"
          formControlName="inputGameCase">
          <mat-option [value]="gameCase" *ngFor="let gameCase of systemService.inputGameCases">
            {{gameCase[systemService.systemLanguage]}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="uniqueError" class="text-danger margin-bottom-thin my-mat-hint">
          {{uniqueError}}<span>&nbsp;</span>
      </div>
    </div>


    <nav>
      <button
        type="submit"
        class="btn btn-primary btn-lg"
        style="margin: 10px; background-color: #233f5d; border-color: #39516b;">
        {{systemService.languageItems[systemService.systemLanguage].registerPayer}}
      </button>
    </nav>
  </form>
  <ng-template #template>
    <div class="modal-body text-center">
      <p>{{message}}</p>
      <button type="button" class="btn btn-default" (click)="confirm()" >Yes</button>
      <button type="button" class="btn btn-primary" (click)="decline()" >No</button>
    </div>
  </ng-template>
</mat-card>

<mat-card style='text-align:center'>

  <mat-table #table  [dataSource]="dataSource"
      class="mat-elevation-z8"
      matSort matSortActive="date"
      matSortDisableClear matSortDirection="asc">
      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
      </ng-container>
      <!-- Name Column -->
      
      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.code}} </mat-cell>
      </ng-container>
      <!-- Weight Column -->
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> {{systemService.languageItems[systemService.systemLanguage].date}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.date}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="project">
        <mat-header-cell *matHeaderCellDef> 
          {{systemService.languageItems[systemService.systemLanguage].projectButton}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button mat-mini-fab color="warn" (click)="routeProject(element)">
              <mat-icon>input</mat-icon>
            </button>
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> 
          {{systemService.languageItems[systemService.systemLanguage].deleteButton}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
            <button mat-mini-fab color="warn" (click)="deleteModal(template, element)">
              <mat-icon>delete</mat-icon>
            </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>
  <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                showFirstLastButtons>
  </mat-paginator>
</mat-card>
