import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { HttpClient,  HttpHeaders, HttpParams } from '@angular/common/http';
import { GameSettings, SystemLanguage } from './data';
import { DatePipe } from '@angular/common';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  language: string = 'de'
  screen: string;
  breakpoint: number;
  fontStyle: string;
  inputGroupStyle:string;
  isHandset: Observable<BreakpointState>;
  isTablet: Observable<BreakpointState>;
  isWeb: Observable<BreakpointState>;
  isPortrait: Observable<BreakpointState>;
  isLandscape: Observable<BreakpointState>;
  guessButton:string;
  skipButton:string;
  guessIcon:string;
  skipIcon:string;
  modalStyle:string;
  cardStyle:string;
  systemLanguage:string = 'de';

inputGameLanguages = [
  {
      'code':'English',
      'en':'English',
      'de':'Englisch'
    },
    {
      'code':'German',
      'en':'German',
      'de':'Deutsch'
    }]

inputGameClasses = [
  {
    'code':'verb',
    'en':'Verb',
    'de':'Verb'
  },
  {
    'code':'noun',
    'en':'Noun',
    'de':'Nomen'
  }
  ]


inputGameCases = [
{
  'code':'association',
  'en':'Association',
  'de':'Assoziation'
},
{
  'code':'context',
  'en':'Context',
  'de':'Kontext'
},
{
  'code':'other',
  'en':'Other',
  'de':'Andere'
}]

  gridByBreakpoints = {
    xl: 4,
    lg: 4,
    md: 4,
    sm: 4,
    xs: 2
  }

  textStyles = {
    xl: "font-size: calc(1.1em);font-weight: bold;",
    lg: "font-size: calc(1.1em);font-weight: bold;",
    md: "font-size: calc(0.9em);font-weight: bold;",
    sm: "font-size: calc(0.75em);",
    xs: "font-size: calc(0.75em);",
  }

  inputGroupStyles= {
    xl: "input-group",
    lg: "input-group",
    md: "input-group input-group-sm",
    sm: "input-group input-group-sm",
    xs: "input-group input-group-sm",
  }

  buttonStyles= {
    xl: "background-color:#B22222; color:#000000",
    lg: "background-color:#B22222; color:#000000",
    md: "background-color:#B22222; color:#000000",
    sm: "input-group input-group-sm",
    xs: "input-group input-group-sm",
  }

  guessEnButtons = {
    xl: "Guess",
    lg: "Guess",
    md: "Guess",
    sm: "",
    xs: "",
  }

  skipEnButtons = {
    xl: "Skip",
    lg: "Skip",
    md: "Skip",
    sm: "",
    xs: "",
  }

  guessDeButtons = {
    xl: "Raten",
    lg: "Raten",
    md: "Raten",
    sm: "",
    xs: "",
  }

  skipDeButtons = {
    xl: "Überspringen",
    lg: "Überspringen",
    md: "Überspringen",
    sm: "",
    xs: "",
  }

  guessIcons = {
    xl: "",
    lg: "",
    md: "",
    sm: "done",
    xs: "done",
  }

  skipIcons = {
    xl: "",
    lg: "",
    md: "",
    sm: "navigate_next",
    xs: "navigate_next",
  }

  modals = {
    xl: "largeModal",
    lg: "largeModal",
    md: "largeModal",
    sm: "largeModal",
    xs: "smallModal",
  }

  cards = {
        xl: "margin: 1% 10% 10% 10%;",
        lg: "margin: 1% 10% 10% 10%;",
        md: "margin: 1% 10% 10% 10%;",
        sm: "margin: 1% 10% 10% 10%;",
        xs: "",
      }

  languageItems = {
      en:{
          confirmQuestion:'Do you confirm the given information?',
          modalName:'Name',
          modalGender: 'Gender',
          modalAge:'Age',
          modalLanguage:'Language',
          modalYesButton:'Yes',
          modalNoButton:'No',
          modalAttention: 'Information',
          loginPlayerPlaceholder: 'Player',
          loginPlayer:'Login',
          registerPayer:'Register',
          skipPlayer:'Skip',
          playWithAccountButton: 'Play with your Account',
          createAPlayerAccount: 'Create a Player Account',
          playWithoutAccount: 'Play without your Account',
          // Register
          namePlayerLabel:'Player name',
          hintNamePlayer:'Enter max 10 characters',
          genderPlayerLabel:'Your gender',
          agePlayerLabel:'Your age',
          nativeLanguagePlayerLabel:'Your native language',
          backButton: 'Home',
          //profile
          updateButton:'Update',
          deleteButton:'Delete',
          projectButton:'Project',
          changeYourGender: 'Update your gender',
          changeYourAge:'Update your age',
          changeYourNativeLanguage:'Update your native language',
          date: 'Date',
          // Decision
          gameDecisionLabel:'Game',
          hintGameDecision:'Select a game',
          targetNumberDecisionLabel: 'Target Number',
          hintTargetNumberDecision: 'Select the number of target words',
          difficultyLevelDecisionLabel:'Difficulty',
          hintDifficultyLevelDecision:'Select the level of difficulty',
          startGame:"Let's Start",
          // Game
          gameTarget:'Target',
          gameScore:'Score',
          gameTotal:'Total',
          extraCue:'Extra Cue',
          gameGuessButton:'Guess',
          gameSkipButton:'Skip',
          gameOverLabel: 'Game Over!',
          tableTarget:'Target',
          tableScore:'Score',
          totalScore: 'Total Score',
          gameAgain:'Again!',
          decisionAgain:'New Game!',
          // NavBar
          sources: 'Sources',
          ims:'IMS',
          legalNotice: 'Legal Notice',
          pravicyNotice: 'Privacy Notice',
          playerProfile: 'Profile',
          playerLogout: 'Logout',
          language: 'Language',
          english: 'English',
          german: 'German',
          //Admin
          wordClass: 'Word class',
          gameCase: 'Condition',
          // Project Settings
          targetOrderProj: 'The target word order',
          contextOrderProj: 'The context word order',
          contextNumberProj: 'The number of contexts of a target',
          extraCueProj: 'The score of extra cue of a target',
          gameDescProj: 'The game description',
          gameLabelProj: 'The game description should be in the game language!',
          randomChoice: 'random',
          orderChoice: 'order',
          highestChoice: 'highest',
          lowestChoice: 'lowest',
          noneChoice: 'none',
          updateGameProj: 'Update Game',
          exportDataProj: 'Export Data',


      },
      de:{
      //modal
          confirmQuestion:'Bestätigen Sie die angegebenen Informationen?',
          modalName:'Name',
          modalGender: 'Geschlecht',
          modalAge:'Alter',
          modalLanguage:'Sprache',
          modalYesButton:'Ja',
          modalNoButton:'Nein',
          modalAttention: 'Beachtung',
      //Player Login
          loginPlayerPlaceholder: 'Spieler*in',
          loginPlayer:'Anmeldung',
          registerPayer:'Registrieren',
          skipPlayer:'Überspringen',
          playWithAccountButton: 'Spiele mit Deinem Spielerkonto',
          createAPlayerAccount: 'Erstelle ein Spielerkonto',
          playWithoutAccount: 'Spielen ohne Spielerkonto',
      //Register
          namePlayerLabel:'Spielername',
          hintNamePlayer:'Gib maximal 10 Zeichen ein',
          genderPlayerLabel:'Dein Geschlecht',
          agePlayerLabel:'Dein Alter',
          nativeLanguagePlayerLabel:'Deine Muttersprache',
          backButton: 'Startseite',
          //profile
          updateButton:'Aktualisieren',
          deleteButton:'Löschen',
          projectButton:'Projekt',
          changeYourGender: 'Aktualisiere dein Geschlecht',
          changeYourAge:'Aktualisiere dein Alter',
          changeYourNativeLanguage:'Aktualisiere deine Muttersprache',

          date: 'Datum',
      // Decision
          gameDecisionLabel:'Spiel',
          hintGameDecision:'Spiel auswählen',
          targetNumberDecisionLabel: 'Anzahl Zielwörter',
          hintTargetNumberDecision: 'Anzahl Zielwörter auswählen',
          difficultyLevelDecisionLabel:'Schwierigkeitsgrad',
          hintDifficultyLevelDecision:'Schwierigkeitsgrad auswählen',
          startGame:"Los geht's!",
      // Game
          gameTarget: 'Zielwort',
          gameScore:'Punkte',
          gameTotal:'Gesamt',
          extraCue:'Tipp',
          gameGuessButton:'Raten',
          gameSkipButton:'Überspringen',
          gameOverLabel: 'Das Spiel ist aus!',
          tableTarget:'Zielwort',
          tableScore:'Punkte',
          totalScore: 'Gesamtpunktzahl',
          gameAgain:'Nochmal!',
          decisionAgain: 'Neues Spiel!',
      // NavBar
          sources: 'Quellen',
          ims:'IMS',
          legalNotice: 'Impressum',
          pravicyNotice: 'Datenschutz',
          playerProfile: 'Profil',
          playerLogout: 'Abmelden',
          language: 'Sprache',
          english: 'Englisch',
          german: 'Deutsch',

      //Admin
          wordClass: 'Wortart',
          gameCase:'Kondition',
      // Project Settings
          targetOrderProj: 'Die Auswahl von Zielwort',
          contextOrderProj: 'Die Auswahl von Kontextwörtern ',
          contextNumberProj: 'Die Anzahl der Kontextwörter festlegen',
          extraCueProj: 'Das extra Kontext-Wort festlegen',
          gameDescProj: 'Die Spielbeschreibung',
          gameLabelProj: 'Die Spielbeschreibung sollte in der Spielsprache sein!',
          randomChoice: 'zufällig',
          orderChoice: 'reihenfolge',
          highestChoice: 'stärkstes',
          lowestChoice: 'unterste',
          noneChoice: 'keines',
          updateGameProj: 'Spiel Aktualisieren',
          exportDataProj: 'Daten Exportieren',
        }
      }

  languageXSItems = {
    en:{
        confirmQuestion:'Do you confirm the given information?',
        modalName:'Name',
        modalGender: 'Gender',
        modalAge:'Age',
        modalLanguage:'Language',
        modalYesButton:'Yes',
        modalNoButton:'No',
        modalAttention: 'Attention',
        loginPlayerPlaceholder: 'Player',
        loginPlayer:'Login',
        registerPayer:'Register',
        skipPlayer:'Skip',
        playWithAccountButton: 'Play with Account',
        createAPlayerAccount: 'Create a Player Account',
        playWithoutAccount: 'Play without Account',
        // Register
        namePlayerLabel:'Player name',
        hintNamePlayer:'Enter max 10 characters',
        genderPlayerLabel:'Your gender',
        agePlayerLabel:'Your age',
        nativeLanguagePlayerLabel:'Your native language',
        backButton: 'Back',
        //profile
        updateButton:'Update',
        changeYourGender: 'Update your gender',
        changeYourAge:'Update your age',
        changeYourNativeLanguage:'Update your native language',
        date: 'Date',
        // Decision
        gameDecisionLabel:'Game',
        hintGameDecision:'Select a game',
        targetNumberDecisionLabel: 'Target Number',
        hintTargetNumberDecision: 'Select the number of target words',
        difficultyLevelDecisionLabel:'Difficulty',
        hintDifficultyLevelDecision:'Select the level of difficulty',
        startGame:"Let's Start",
        // Game
        gameTarget:'Target',
        gameScore:'Score',
        gameTotal:'Total',
        extraCue:'Extra Cue',
        gameGuessButton:'Guess',
        gameSkipButton:'Skip',
        gameOverLabel: 'Game Over!',
        tableTarget:'Target',
        tableScore:'Score',
        totalScore: 'Total Score',
        gameAgain:'Again!',
        decisionAgain:'New Game!',
        // NavBar
        sources: 'Sources',
        ims:'IMS',
        legalNotice: 'Legal Notice',
        pravicyNotice: 'Privacy Notice',
        playerProfile: 'Profile',
        playerLogout: 'Logout',
        language: 'Language',
        english: 'English',
        german: 'German',



    },
    de:{
      //modal
        confirmQuestion:'Bestätigen Sie die angegebenen Informationen?',
        modalName:'Name',
        modalGender: 'Geschlecht',
        modalAge:'Alter',
        modalLanguage:'Sprache',
        modalYesButton:'Ja',
        modalNoButton:'Nein',
        modalAttention: 'Beachtung',
    //Player Login
        loginPlayerPlaceholder: 'Spielerin',
        loginPlayer:'Anmeldung',
        registerPayer:'Registrieren',
        skipPlayer:'Überspringen',
        playWithAccountButton: 'Spielen Sie mit dem Konto',
        createAPlayerAccount: 'Erstellen Sie ein Spielerkonto',
        playWithoutAccount: 'Spielen Sie ohne Konto',
    //Register
        namePlayerLabel:'Spielername',
        hintNamePlayer:'Geben Sie maximal 10 Zeichen ein',
        genderPlayerLabel:'Dein Geschlecht',
        agePlayerLabel:'Dein Alter',
        nativeLanguagePlayerLabel:'Dein Muttersprache',
        backButton: 'Zurück',
        //profile
        updateButton:'Aktualisieren',
        changeYourGender: 'Aktualisieren Sie Ihr Geschlecht',
        changeYourAge:'Aktualisiere dein Alter',
        changeYourNativeLanguage:'Aktualisieren Sie Ihre Muttersprache',
        date: 'Datum',
      // Decision
        gameDecisionLabel:'Spiel',
        hintGameDecision:'Spiel auswählen',
        targetNumberDecisionLabel: 'Anzahl Zielwörter',
        hintTargetNumberDecision: 'Anzahl Zielwörter auswählen',
        difficultyLevelDecisionLabel:'Schwierigkeitsgrad',
        hintDifficultyLevelDecision:'Schwierigkeitsgrad auswählen',
        startGame:"Los geht's!",
        // Game
        gameTarget: 'Zielwort',
        gameScore:'Punkte',
        gameTotal:'Gesamt',
        extraCue:'Tipp',
        gameGuessButton:'Raten',
        gameSkipButton:'Überspringen',
        gameOverLabel: 'Das Spiel ist aus!',
        tableTarget:'Zielwort',
        tableScore:'Punkte',
        totalScore: 'Gesamtpunktzahl',
        gameAgain:'Nochmal!',
        decisionAgain: 'Neues Spiel!',
        // NavBar
        sources: 'Quellen',
        ims:'IMS',
        legalNotice: 'Impressum',
        pravicyNotice: 'Datenschutz',
        playerProfile: 'Profil',
        playerLogout: 'Abmelden',
        language: 'Sprache',
        english: 'Englisch',
        german: 'Deutsch',
      }
  }

  screen2Language = {
    xl: {
      en:this.languageItems['en'],
      de:this.languageItems['de']
    },
    lg: {
      en:this.languageItems['en'],
      de:this.languageItems['de']
    },
    md: {
      en:this.languageItems['en'],
      de:this.languageItems['de']
    },
    sm: {
      en:this.languageItems['en'],
      de:this.languageItems['de']
    },
    xs: {
      en:this.languageXSItems['en'],
      de:this.languageXSItems['de']
    },
  }

  modalText = {
    en:{
      playerNotFound:'Player is not found!',
      playerExist: 'Name is already taken!',
      playerRegistered: 'Player is successfully registered!',
      updatedSaved: 'Update is successful!',
    },
    de:{
      playerNotFound:'Der Spieler wurde nicht gefunden!',
      playerExist: 'Name ist bereits vergeben!',
      playerRegistered: 'Spieler ist erfolgreich registriert!',
      updatedSaved: 'Update ist erfolgreich!',
    }

  }

  Genders: SystemLanguage[] = [{code:'none', en:'Do not want to specify', de:'keine Angabe'},
                               {code:'f', en:'Female', de:'Weiblich'},
                               {code:'m', en:'Male', de:'Männlich'},
                               {code:'mix', en:'Mixed', de:'Gemischt'}];

  Ages: SystemLanguage[] = [{code:'none', en:'Do not want to specify', de:'keine Angabe'},
                            {code:'10-15', en:'10-15', de:'10-15'},
                            {code:'16-20', en:'16-20', de:'16-20'},
                            {code:'21-25', en:'21-25', de:'21-25'},
                            {code:'26-30', en:'26-30', de:'26-30'},
                            {code:'31-35', en:'31-35', de:'31-35'},
                            {code:'36-40', en:'36-40', de:'36-40'},
                            {code:'41-45', en:'41-45', de:'41-45'},
                            {code:'46-50', en:'46-50', de:'46-50'},
                            {code:'51-55', en:'51-55', de:'51-55'},
                            {code:'56-60', en:'56-60', de:'56-60'},
                            {code:'61-65', en:'61-65', de:'61-65'},
                            {code:'66-70', en:'66-70', de:'66-70'},
                            {code:'71-75', en:'71-75', de:'71-75'},
                            {code:'76-80', en:'76-80', de:'76-80'}];


  settingsUri = '/wordguess_gameserver/settings'
  constructor(private mediaObserver: MediaObserver,
              private mqm: BreakpointObserver,
              private http: HttpClient) {
              var activeMediaQuery = ''
              this.mediaObserver.media$.subscribe((change: MediaChange) => {
                 activeMediaQuery = change ? `'${change.mqAlias}' =
                                        (${change.mediaQuery})` : '';
                this.screen = change.mqAlias;

                this.breakpoint = this.gridByBreakpoints[this.screen];
                this.fontStyle= this.textStyles[this.screen];
                this.inputGroupStyle = this.inputGroupStyles[this.screen];
                this.guessButton = this.systemLanguage == 'en' ? this.guessEnButtons[this.screen] : this.guessDeButtons[this.screen]
                this.skipButton = this.systemLanguage == 'en' ? this.skipEnButtons[this.screen]:this.skipDeButtons[this.screen]
                this.guessIcon = this.guessIcons[this.screen]
                this.skipIcon = this.skipIcons[this.screen]
                this.modalStyle = this.modals[this.screen]
                this.cardStyle = this.cards[this.screen]
              });
   }

   addSettings(gameSettings: any): Observable<any> {
     return this.http.post<any>(`${this.settingsUri}/add`, gameSettings);
   }

   getGames():Observable<any[]>{
     const gameObservable = this.http.get<any[]>(`${this.settingsUri}/getGames`, {});
     return gameObservable;
   }

   getGameByCode(code:string):any{
      return this.http.post<any>(`${this.settingsUri}/getGameByCode`, {code:code})
   }

   updateProject(gameSettings: any): Observable<any> {
     return this.http.post<any>(`${this.settingsUri}/update`, gameSettings);
   }

}
