<div *ngIf="systemService.screen=='xs'; then thenBlock else elseBlock"></div>
<ng-template #thenBlock>
<button mat-button [matMenuTriggerFor]="animals"><mat-icon>menu</mat-icon></button>
<mat-menu #animals="matMenu">
  <button style="font-size: 11px;" *ngIf="isPalyer" mat-menu-item [matMenuTriggerFor]="playerMenu">
    {{playerName}}</button>

  <button style="font-size: 11px;" *ngIf="page === 'game'" mat-menu-item >
    {{systemService.languageItems[systemService.systemLanguage].backButton}}</button>


  <button style="font-size: 11px;" mat-menu-item [matMenuTriggerFor]="vertebrates">
    {{systemService.languageItems[systemService.systemLanguage].sources}}</button>

  <a *ngIf="systemService.systemLanguage=='de'" style="font-size: 11px;" mat-menu-item href="https://www.ims.uni-stuttgart.de/" target="_blank">
    {{systemService.languageItems[systemService.systemLanguage].ims}}</a>

  <a *ngIf="systemService.systemLanguage=='en'" style="font-size: 11px;" mat-menu-item href="https://www.ims.uni-stuttgart.de/en/" target="_blank">
    {{systemService.languageItems[systemService.systemLanguage].ims}}</a>

  <a *ngIf="systemService.systemLanguage=='de'" style="font-size: 11px;" mat-menu-item href="https://www.ims.uni-stuttgart.de/impressum/index.html" target="_blank">
    {{systemService.languageItems[systemService.systemLanguage].legalNotice}}</a>

  <a *ngIf="systemService.systemLanguage=='en'" style="font-size: 11px;" mat-menu-item href="https://www.uni-stuttgart.de/en/legal-notice/" target="_blank">
  {{systemService.languageItems[systemService.systemLanguage].legalNotice}}</a>

  <a *ngIf="systemService.systemLanguage=='de'" style="font-size: 11px;" mat-menu-item href="https://www.uni-stuttgart.de/datenschutz/" target="_blank">
    {{systemService.languageItems[systemService.systemLanguage].pravicyNotice}}</a>

  <a *ngIf="systemService.systemLanguage=='en'" style="font-size: 11px;" mat-menu-item href="https://www.uni-stuttgart.de/en/privacy-notice/" target="_blank">
  {{systemService.languageItems[systemService.systemLanguage].pravicyNotice}}</a>

  <button style="font-size: 11px;" mat-menu-item [matMenuTriggerFor]="LanguageMenu">{{systemService.languageItems[systemService.systemLanguage].language}}</button>

</mat-menu>

<mat-menu #playerMenu="matMenu" yPosition="below">
  <a style="font-size: 9px;" class="nav-link"
     (click)="goPlayerProfile()">
     {{systemService.languageItems[systemService.systemLanguage].playerProfile}}
   </a>
   <a style="font-size: 9px;" class="nav-link"
      (click)="logOut()">
      {{systemService.languageItems[systemService.systemLanguage].playerLogout}}
    </a>
</mat-menu>

<mat-menu #vertebrates="matMenu" #belowMenu="matMenu" yPosition="below">
  <a style="font-size: 9px;" class="nav-link"
     href="https://link.springer.com/article/10.3758/BF03195588"
     target="_blank">
     Douglas L. Nelson, Cathy McEvoy and Thomas A. Schreiber (2004)
   </a>
  <a style="font-size: 9px;" class="nav-link"
     href="http://www.coli.uni-saarland.de/projects/nag/"
     target="_blank">
     Alissa Melinger and Andrea Weber (2006)
  </a>
  <a style="font-size: 9px;" class="nav-link"
     href="https://link.springer.com/article/10.1007/s11168-008-9048-4"
     target="_blank">
     Sabine Schulte im Walde, Alissa Melinger, Michael Roth and Andrea Weber (2008)
  </a>
</mat-menu>

<mat-menu #LanguageMenu="matMenu" yPosition="below">
  <a style="font-size: 9px;" class="nav-link"
     (click)="changeLanguge('en')">
     {{systemService.languageItems[systemService.systemLanguage].english}}
   </a>
   <a style="font-size: 9px;" class="nav-link"
      (click)="changeLanguge('de')">
      {{systemService.languageItems[systemService.systemLanguage].german}}
    </a>
</mat-menu>
<mat-card class="xsClass">
  <app-nav-bar></app-nav-bar>
  <router-outlet></router-outlet>
</mat-card>
</ng-template>

<ng-template #elseBlock>
<ul class="nav justify-content-center">
  <button mat-button [matMenuTriggerFor]="belowMenu">{{systemService.languageItems[systemService.systemLanguage].sources}}</button>
  <mat-menu #belowMenu="matMenu" yPosition="below">
    <a style="font-size: 10px;" class="nav-link"
       href="https://link.springer.com/article/10.3758/BF03195588"
       target="_blank">
       Douglas L. Nelson, Cathy McEvoy and Thomas A. Schreiber (2004)
     </a>
    <a style="font-size: 10px;" class="nav-link"
       href="http://www.coli.uni-saarland.de/projects/nag/"
       target="_blank">
       Alissa Melinger and Andrea Weber (2006)
    </a>
    <a style="font-size: 10px;" class="nav-link"
       href="https://link.springer.com/article/10.1007/s11168-008-9048-4"
       target="_blank">
       Sabine Schulte im Walde, Alissa Melinger, Michael Roth and Andrea Weber (2008)
    </a>
  </mat-menu>

  <a *ngIf="systemService.systemLanguage=='de'"  mat-menu-item href="https://www.ims.uni-stuttgart.de/" target="_blank">
    {{systemService.languageItems[systemService.systemLanguage].ims}}</a>

  <a *ngIf="systemService.systemLanguage=='en'" mat-menu-item href="https://www.ims.uni-stuttgart.de/en/" target="_blank">
    {{systemService.languageItems[systemService.systemLanguage].ims}}</a>


  <a *ngIf="systemService.systemLanguage=='de'"  mat-menu-item href="https://www.ims.uni-stuttgart.de/impressum/index.html" target="_blank">
    {{systemService.languageItems[systemService.systemLanguage].legalNotice}}</a>

  <a *ngIf="systemService.systemLanguage=='en'"  mat-menu-item href="https://www.uni-stuttgart.de/en/legal-notice/" target="_blank">
  {{systemService.languageItems[systemService.systemLanguage].legalNotice}}</a>

  <a *ngIf="systemService.systemLanguage=='de'"  mat-menu-item href="https://www.uni-stuttgart.de/datenschutz/" target="_blank">
    {{systemService.languageItems[systemService.systemLanguage].pravicyNotice}}</a>

  <a *ngIf="systemService.systemLanguage=='en'"  mat-menu-item href="https://www.uni-stuttgart.de/en/privacy-notice/" target="_blank">
  {{systemService.languageItems[systemService.systemLanguage].pravicyNotice}}</a>
  <!--
  Admin create researchers, or deactivate them
  <a style="font-size: 11px;"  mat-menu-item (click)='goGameDecision()'>Game</a>
  -->
  <button mat-button [matMenuTriggerFor]="LanguageMenu">{{systemService.languageItems[systemService.systemLanguage].language}}</button>
  <mat-menu #LanguageMenu="matMenu" yPosition="below">
    <a style="font-size: 10px;" class="nav-link"
       (click)="changeLanguge('en')">
       {{systemService.languageItems[systemService.systemLanguage].english}}
     </a>
     <a style="font-size: 10px;" class="nav-link"
        (click)="changeLanguge('de')">
        {{systemService.languageItems[systemService.systemLanguage].german}}
      </a>
  </mat-menu>

  <button *ngIf="page === 'game'" mat-button (click)='goGameDecision()'>{{systemService.languageItems[systemService.systemLanguage].backButton}}</button>  

  <button  *ngIf="isPalyer" mat-button [matMenuTriggerFor]="playerMenu">{{playerName}}</button>
  <mat-menu #playerMenu="matMenu" yPosition="below">
    <a style="font-size: 10px;" class="nav-link"
       (click)="goPlayerProfile()">
       {{systemService.languageItems[systemService.systemLanguage].playerProfile}}
     </a>
     <a style="font-size: 10px;" class="nav-link"
        (click)="logOut()">
        {{systemService.languageItems[systemService.systemLanguage].playerLogout}}
      </a>
  </mat-menu>

</ul>
<mat-card class="bigClass">
  <div  *ngIf="systemService.systemLanguage === 'en' && (page === 'player' || page === 'decision')">
    <blockquote>
      <p style="background-color:#ecae5a; font-weight: bold;">Click & Guess - The IMS Word Guessing Game</p>

      <p *ngIf="isGameDesc === false">We show you a grid with cue words which you can click in any order.
      Guess the target word that is related to all word cues (sometimes more
      and sometimes less)!

      <p *ngIf="isGameDesc === false">The less cues you need to guess the correct word, the higher your
      score. But if you make a wrong guess your score is decreased by 3 ...
    <p *ngIf="isGameDesc"> {{gameDesc}}
  <i>IMS</i></blockquote>
  </div>
  <div *ngIf="systemService.systemLanguage === 'de' && (page === 'player' || page === 'decision')">
    <blockquote>
    <p style="background-color:#ecae5a; font-weight: bold;">Klicken & Raten - Das IMS-Wort-Rate-Spiel</p>

    <p *ngIf="isGameDesc === false">Wir zeigen Dir ein Gitter mit Wörtern, die Du in beliebiger
    Reihenfolge anklicken kannst. Rate das Zielwort, auf das sich alle
    Wörter (mal mehr und mal weniger) beziehen!

    <p *ngIf="isGameDesc === false" >Je weniger Wörter Du anklicken musst um das korrekte Zielwort zu
    raten, umso mehr Punkte erzielst Du! Aber jedes Mal wenn Du falsch
    rätst werden Dir drei Punkte abgezogen ...

    <p *ngIf="isGameDesc"> {{gameDesc}}

    <i>IMS</i></blockquote>
  </div>

  <div *ngIf="page === 'project'">
  <blockquote *ngIf="systemService.systemLanguage=='en'" style="margin: 0 0 -5rem !important;">
  <p style="background-color:#ecae5a; font-weight: bold;">Click & Guess - The IMS Word Guessing Game</p>
  <dl>
  <dt style="text-align: justify;font-size: 11px; margin-bottom: -0.5rem;">Please take the following suggestions into account when setting up a new game.</dt>
  <dd style="text-align: justify;font-size: 11px; margin-bottom: -0.5rem; line-height: 120%;">Make sure you upload a json file with a minimum of 10 target words as 'target-context-score' json object. If there is no score then set it to "0". Each target word needs at least as many context words as provided by the grid, plus one extra context word. For example, if the selected number of context words is 20, then the minimum number of context words should be 21
  Please download the <a [href]="fileUrl" download="examples.json">examples</a>, and see how you can organise the 'target-context-score'.
  </dl>
  <dl>
  <dd style="text-align: justify;font-size: 11px; font-weight: bold;margin-bottom: -0.5rem;">You have the following options to define your game:</dd>
  <dd style="text-align: justify;font-size: 11px;margin-bottom: -0.5rem;">- You can define the reading order of target and context words as "random" or "order".</dd>
  <dd style="text-align: justify;font-size: 11px;margin-bottom: -0.5rem;">- You can define the number of context words shown to players for the targets.</dd>
  <dd style="text-align: justify;font-size: 11px;margin-bottom: -0.5rem;">- You can define how the extra context cue word is selected. </dd>
  <dd style="text-align: justify;font-size: 11px;margin-bottom: -0.5rem;">- The game description corresponds to a brief explanation of the game that the player will see. </dd>


  </dl>
    <i>IMS</i></blockquote>

  <blockquote *ngIf="systemService.systemLanguage=='de'" style="margin: 0 0 -5rem !important;">
  <p style="background-color:#ecae5a; font-weight: bold;">Klicken & Raten - Das IMS-Wort-Rate-Spiel</p>
  <dl>
  <dt style="text-align: justify;font-size: 11px; margin-bottom: -0.5rem;">Bitte beachten Sie die folgenden Hinweise beim Aufsetzen eines Spiels.</dt>
  <dd style="text-align: justify;font-size: 11px; margin-bottom: -0.5rem; line-height: 120%;">Sie müssen eine json-Datei mit mindestens 10 Zielwörtern hochladen, mit einem 'target-context-score' json-Objekt. Falls es keinen Wert (score) gibt, sollte er auf 0 gesetzt werden Jedes Zielwort (target) benötigt so viele Kontext-Wörter (context) wie im Gitter angegeben werden, plus eins für das extra Kontext-Wort. Falls Sie z.B. 20 Kontextwörter für das Gitter definieren, brauchen Sie tatsächlich mindestens 21 Kontextwörter für jedes Zielwort. 
  Sie können sich <a [href]="fileUrl" download="examples.json">Beispieldateien</a> herunterladen, um zu sehen, wie das 'target-context-score'-Objekt organisiert werden kann.
  </dl>
  <dl>
  <dd style="text-align: justify;font-size: 11px; font-weight: bold;margin-bottom: -0.5rem;">Ihnen stehen die folgenden Optionen zur Auswahl für die Definition eines Spiels:</dd>
  <dd style="text-align: justify;font-size: 11px;margin-bottom: -0.5rem;">- Sie können die Auswahl von Zielwort und Kontextwörtern als "zufällig" oder "geordnet" (wie in der Datei) festlegen.</dd>
  <dd style="text-align: justify;font-size: 11px;margin-bottom: -0.5rem;">- Sie können die Anzahl der Kontextwörter festlegen.</dd>
  <dd style="text-align: justify;font-size: 11px;margin-bottom: -0.5rem;">- Sie können festlegen, wie das extra Kontext-Wort ausgewählt wird.</dd>
  <dd style="text-align: justify;font-size: 11px;margin-bottom: -0.5rem;">- Die Spielbeschreibung entspricht der Kurz-Erklärung, die der/die Spieler*in sieht.</dd>


  </dl>
    <i>IMS</i></blockquote>

  </div>
  <app-nav-bar></app-nav-bar>
  <router-outlet></router-outlet>
</mat-card>
</ng-template>
