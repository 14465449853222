<mat-card class="content">
  <form [formGroup]="userForm" (ngSubmit)="login()">
    <div class="form-group p-2">
      <mat-form-field style="min-width: 225px !important;" hintLabel="Enter the project name">
        <input matInput
        formControlName="inputUserName"
        placeholder="Project name"
        name="username">
      </mat-form-field>
    </div>
    <div class="form-group p-2">
      <mat-form-field style="min-width: 225px !important;" hintLabel="Enter the given code">
        <input matInput
        formControlName="inputUserCode"
        maxlength="10"
        placeholder="Project code"
        name="usercode">
      </mat-form-field>
    </div>
    <div *ngIf="researcherExistError" class="text-danger margin-bottom-thin my-mat-hint">
      {{researcherExistError}}<span>&nbsp;</span>
    </div>
    <button class="btn btn-primary btn-lg"
      (click)=login()
      style="margin: 10px; background-color: #233f5d; border-color: #39516b;">
      Login
    </button>
  </form>
</mat-card>
