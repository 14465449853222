import { Component, OnInit, TemplateRef} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { FileObject, GameSettings, OnlineReseacher, ExportData, Export, UpdateGameSettings } from '../data';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { SystemService } from '../system.service';
import { UserService } from '../user.service';
import { DataService } from '../data.service';
import { saveAs } from 'file-saver';
const UploadURL = 'http://localhost:3000/api/uploadfile';


interface IData {
   pairs: [];
}


@Component({
  selector: 'app-researcher',
  templateUrl: './researcher.component.html',
  styleUrls: ['./researcher.component.css']
})
export class ResearcherComponent implements OnInit {
  isPageLoad = false;
  isUpdate = false;
  game: GameSettings;
  researcher = OnlineReseacher;
  contextOrderSelected = 'random'
  targetOrderSelected = 'random'
  contextNumberSelected = '4'
  hintOrderSelected = 'random'
  uploadedFileObject: any;
  uploadedFile: FileObject;
  modalRef: BsModalRef;
  message: string;
  data: IData;
  dataContextLen: number[] = [];
  isDataRead: boolean = false;
  datePipeEn: DatePipe = new DatePipe('en-US')
  settingObject: GameSettings;
  settingsForm = new FormGroup({
    descriptionControl: new FormControl('', [Validators.required]),
  });
  isModalShown = false;
  errorMessage = '';
  export: ExportData[] = [];
  constructor(public fb: FormBuilder,
              private modalService: BsModalService,
              public userService: UserService,
              public systemService: SystemService,
              private dataService: DataService,
              private route: ActivatedRoute,
              private router: Router) {
                sessionStorage.removeItem('current_page');
                sessionStorage.setItem('current_page', 'project');
    }

  //It needs refresh I didnot understand look at it
  ngOnInit(): void {
    this.loadGame();
  }

loadGame(): void{
  let researcher = JSON.parse(sessionStorage.getItem('onlineReseacher'))
  this.researcher = researcher;
  if(researcher){
    this.isPageLoad = true;
    this.systemService.getGameByCode(researcher.code)
    .subscribe(game =>{
      if (game.result){
        if (game.token && game.token.length == 1){
          this.game = game.token[0];
          this.isUpdate = true;
          this.targetOrderSelected = this.game.targetOrder;
          this.contextOrderSelected = this.game.contextOrder;
          this.contextNumberSelected = this.game.contextNumber.toString();
          this.hintOrderSelected = this.game.hintOrder;
          this.settingsForm.controls['descriptionControl'].setValue(this.game.description);
        }else{
          this.isUpdate = false;
        }
      }else{
        this.isUpdate = false;
      }
    });
  }else{
    this.isPageLoad = false;
    this.router.navigate(['/project']);
    }
}

jsonTargetCheck(jsonSession){
    let message = {isOk:true, desc:''}
    if(jsonSession[0].hasOwnProperty('target')){
      message.isOk = true;
      message.desc = '';
      return message;
    }else{
      message.isOk = false;
      message.desc = 'No target key in the file, check the format!';
      return message;
    }
  }

  jsonTargetNumberCheck(jsonSession){
    let keys = Object.keys(jsonSession);
    console.log(keys.length);
    let message = {isOk:true, desc:''}
      if (keys.length < 10){
        message.isOk = false;
        message.desc = 'Target length should equal to 10 or greater than 10';
        return message
      }else{
        return message
      }
    }



  readFile(template: TemplateRef<any>) {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      let groupByTarget: IData = {
        pairs: []
      };
      let jsonSession = JSON.parse(reader.result.toString());
      let messageTarget = this.jsonTargetCheck(jsonSession)
      if (messageTarget.isOk){
        jsonSession.forEach(function (dn) {
          groupByTarget [dn.target] = groupByTarget [dn.target] || [];
          groupByTarget [dn.target].push({ context: dn.context, score: dn.score });
        });
        console.log('groupByTarget');

        console.log(groupByTarget);
        delete groupByTarget['pairs'];
        let messageTargetNumber = this.jsonTargetNumberCheck(groupByTarget)
        if (messageTargetNumber.isOk){
          if (groupByTarget.hasOwnProperty("pairs")){
            delete groupByTarget['pairs']
          }
          this.data = groupByTarget;
          for (let key in this.data) {
            if (this.data[key].length == 0){
            }else{
              this.dataContextLen.push(this.data[key].length)
            }
          }
          this.isDataRead = true;
        }else{
          (<HTMLInputElement>document.getElementById('fileInput')).value = '';
          this.errorModal(template, messageTargetNumber.desc)
        }
      }
      else{
        (<HTMLInputElement>document.getElementById('fileInput')).value = '';
        this.errorModal(template, messageTarget.desc)
      }
    }
    reader.readAsText(this.uploadedFileObject.target.files[0]);
  }

  setGame(template: TemplateRef<any>): void{
    if (this.isDataRead){
      if (this.settingsForm.invalid) {
        this.settingsForm.get('descriptionControl').markAsTouched();
        return;
      }else{
        var min = Math.min.apply(null, this.dataContextLen);
          if (this.contextNumberSelected <= min){
            let settingObject: GameSettings = {
              gameCode: JSON.parse(sessionStorage.getItem('onlineReseacher')).code.toString(),
              data: JSON.stringify(this.data),
              researcher: sessionStorage.getItem('onlineReseacher').toString(),
              name:JSON.parse(sessionStorage.getItem('onlineReseacher')).name.toString(),
              targetOrder:this.targetOrderSelected,
              contextOrder:this.contextOrderSelected,
              contextNumber:this.contextNumberSelected,
              hintOrder: this.hintOrderSelected,
              description:(<HTMLInputElement>document.
                                     getElementById('gameDescription')).value,
              date:this.datePipeEn.transform(new Date()).toString(),
              isActive:true
            };
              this.systemService.addSettings(settingObject)
                                 .subscribe(res => {
                                   if (res.result){
                                     this.isUpdate = true;
                                    this.errorModal(template, res.message);
                                    this.loadGame();
                                   }
                                 });

          }else{
            this.errorModal(template, 'Some targets do not have enough object!');
          }
      }
    }else{
      this.errorModal(template, 'Upload the file!');

    }
  }

  updateGame(template: TemplateRef<any>): void{
    if (this.settingsForm.invalid) {
      this.settingsForm.get('descriptionControl').markAsTouched();
      return;
    }else{
      var min = Math.min.apply(null, this.dataContextLen);
        if (this.contextNumberSelected < min){
          let game = this.game

          let settingObject: UpdateGameSettings = {
            _id:this.game['_id'],
            gameCode: this.game.gameCode.toString(),
            data: this.game.data.toString(),
            researcher: this.game.researcher.toString(),
            name:this.game.name.toString(),
            targetOrder:this.targetOrderSelected.toString(),
            contextOrder:this.contextOrderSelected,
            contextNumber:this.contextNumberSelected.toString(),
            hintOrder: this.hintOrderSelected.toString(),
            description:(<HTMLInputElement>document.
                                   getElementById('gameDescription')).value.toString(),
            date:this.datePipeEn.transform(new Date()).toString(),
            isActive:true
          };
          this.systemService.updateProject(settingObject)
                               .subscribe(res => {
                                this.isUpdate = true;
                                this.errorModal(template, res.message);
                               });
        }else{
          this.errorModal(template, 'Some targets do not have enough object!');
        }
    }
  }

  errorModal(template: TemplateRef<any>, message) {
    this.errorMessage = message;
    this.modalRef = this.modalService.show(template, {
      ariaDescribedby: 'my-modal-description',
      ariaLabelledBy: 'my-modal-title'
    });
    setTimeout(function() {
      this.modalRef.hide();
      this.errorMessage = ''
    }.bind(this),5000);
  }

  openFileModal(template: TemplateRef<any>, errortemplate: TemplateRef<any> , event) {
    let files = event.target.files;
    if (files[0].type == "application/json"){
      if (files.length !== 1) {
        this.errorModal(errortemplate, 'File is empty!')
      } else {
        const file = (files as HTMLInputElement);
        this.uploadedFile = {
          name:file[0].name,
          type:file[0].type
        }
      }
      this.message = 'Do you confirm '+ this.uploadedFile.name +' ?'
      this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
      this.uploadedFileObject = event;

    }else{
      (<HTMLInputElement>document.getElementById('fileInput')).value = '';
      this.errorModal(errortemplate, 'Json file please!')
    }
  }

  confirm(template: TemplateRef<any>): void {
    this.message = 'Confirmed!';
    this.readFile(template)
    this.modalRef.hide();
  }

  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }

  exportGame(errortemplate: TemplateRef<any>):void{
    this.dataService.getProjectActions(this.game.gameCode).subscribe(res => {
      let data = res.token;

      if (data.length > 0 ) {
        for (let key in data) {
          this.export.push({
                _id : data[key]['_id'],
                gameCode : data[key]['gameCode'],
                player : JSON.parse(data[key]['player']),
                decision : JSON.parse(data[key]['decision']),
                game : JSON.parse(data[key]['game']),
                actions : JSON.parse(data[key]['actions']),
                date : data[key]['date'],
                totalScore : data[key]['totalScore']
          });
        }
        let exportString = JSON.stringify(this.export);
        var blob = new Blob([exportString], {type: 'json;charset=utf-8'});
        saveAs(blob, this.researcher.name+'_actions.json');
        this.errorModal(errortemplate, 'Please check your download folder for '+this.researcher.name+'_actions.json');
      }else{
        this.errorModal(errortemplate, 'No data for export!');
      }
    })
  }
}
