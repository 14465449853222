import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, Validators, FormGroup, FormArray} from '@angular/forms';
import { DataService } from '../data.service';
import { UserService } from '../user.service';
import { SystemService } from '../system.service';
import { NativeLan, PlayerRegisteration, SystemLanguage} from '../data';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {
  isLogin: boolean = true;
  error:string = ''
  nativeLanguageArray: SystemLanguage[];
  datePipeEn: DatePipe = new DatePipe('en-US')
  loginForm: FormGroup = new FormGroup({
    inputPlayerNameLogin: new FormControl('', [Validators.required])
  });
  registerationForm = new FormGroup({
    inputPlayerName: new FormControl('', [Validators.required]),
    inputPlayerAge: new FormControl('', [Validators.required]),
    inputPlayerGender: new FormControl('', [Validators.required]),
    inputPlayerNativeLanguage: new FormControl('', [Validators.required]),
  });
  selectedAge:string;
  selectedGender:string;
  selectedNativeLanguage:string;
  registerationModalMessage:string;
  errorMessage:string;
  isConfirm:boolean = false;
  modalRef: BsModalRef;
  player: PlayerRegisteration;
  modalPlayer: PlayerRegisteration;
  errorTemplate: TemplateRef<any>;
  constructor(public dataService: DataService,
              public userService: UserService,
              public systemService: SystemService,
              private modalService: BsModalService,
              private route: ActivatedRoute,
              private router: Router) {
                sessionStorage.removeItem('current_page');
                sessionStorage.setItem('current_page', 'player');
              }

  ngOnInit(): void {
    this.nativeLanguageArray = this.dataService.getNativeLangs();
    if (this.userService.isLoggedIn()){
      this.router.navigate(['/decision']);
    }
  }

  submitLogin(loginFormTemplate, template: TemplateRef<any>):void{
    this.errorTemplate = template;
    if (this.loginForm.invalid) {
      this.loginForm.get('inputPlayerNameLogin').markAsTouched();
      return;
    }else{
      this.login(loginFormTemplate.inputPlayerNameLogin)
    }
  }

  login(playerName:string):void{
    this.userService.login(playerName)
      .subscribe((res: any) => {
          this.userService.setPlayerLoggedIn(res.result, res);
          if (res.result){
            this.router.navigate(['/decision']);
          }else{
            this.errorModal(this.errorTemplate, this.systemService.modalText[this.systemService.systemLanguage].playerNotFound);
            this.loginForm.reset();
          }
      });
  }

  goRegisterPage():void{
    this.isLogin = false;
  }

  goLoginPage():void{
    this.isLogin = true;
  }

  skipLogin():void{
    this.userService.setPlayerLoggedIn(false, {});
    this.router.navigate(['/decision']);
  }

  errorModal(template: TemplateRef<any>, message) {
    this.errorMessage = message;
    this.modalRef = this.modalService.show(template, {
      ariaDescribedby: 'my-modal-description',
      ariaLabelledBy: 'my-modal-title'
    });
    setTimeout(function() {
      this.modalRef.hide();
      this.errorMessage = ''
    }.bind(this),5000);
  }

  openFileModal(template: TemplateRef<any>, errortemplate: TemplateRef<any>, registerationFormTemplate) {
    this.errorTemplate = errortemplate;
    if (this.registerationForm.invalid) {
        this.registerationForm.get('inputPlayerName').markAsTouched();
        this.registerationForm.get('inputPlayerAge').markAsTouched();
        this.registerationForm.get('inputPlayerGender').markAsTouched();
        this.registerationForm.get('inputPlayerNativeLanguage').markAsTouched();
        return;
     }else{
       this.player = {
          name: registerationFormTemplate.inputPlayerName,
          gender: JSON.stringify(registerationFormTemplate.inputPlayerGender),
          age: JSON.stringify(registerationFormTemplate.inputPlayerAge),
          nativeLanguage: JSON.stringify(registerationFormTemplate.inputPlayerNativeLanguage),
          date:this.datePipeEn.transform(new Date()).toString()
        }
        this.modalPlayer = {
          name: registerationFormTemplate.inputPlayerName,
          gender: registerationFormTemplate.inputPlayerGender[this.systemService.systemLanguage],
          age: registerationFormTemplate.inputPlayerAge[this.systemService.systemLanguage],
          nativeLanguage: registerationFormTemplate.inputPlayerNativeLanguage[this.systemService.systemLanguage],
          date:this.datePipeEn.transform(new Date()).toString()
        }
        this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
      }
  }

  register():void{
    this.userService.addPlayer(this.player)
      .subscribe(res => {
        if (res.result){
          this.errorModal(this.errorTemplate, this.systemService.modalText[this.systemService.systemLanguage].playerRegistered);
          this.login(res.token.name)
        }else{
          this.errorModal(this.errorTemplate, this.systemService.modalText[this.systemService.systemLanguage].playerExist);
          this.registerationForm.reset();
        }
    });
  }

  confirm(): void {
    this.isConfirm = true;
    this.register();
    this.modalRef.hide();
  }

  decline(): void {
    this.isConfirm = false;
    this.registerationForm.reset()
    this.modalRef.hide();
  }
}
