import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, Validators, FormGroup} from '@angular/forms';
import { SystemService } from '../system.service';
import { UserService } from '../user.service';
import { Researcher, Project } from '../data';
import { HttpClient } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { LoginResearcher } from '../data';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  langs = {
    German:'de',
    English:'en'
  }

  classes = [
    {
      'code':'v',
      'en':'Verb',
      'de':'Verb'
    },
    {
      'code':'n',
      'en':'Noun',
      'de':'Nomen'
    }
  ]

  allResearchers: Researcher[];
  uniqueError: string;
  modalRef: BsModalRef;
  message: string;
  errorMessage:string;
  mode:string;
  researcherName:string;

  project: Project;
  projectName:string;
  projectLanguage:string;
  projectWordClass:string;
  projectCase:string;
  selectedGameLanguage: string;
  selectedGameClass: string;
  selectedGameCase: string;

  modalDeleteObject: Project;

  errorTemplate: TemplateRef<any>;
  datePipeEn: DatePipe = new DatePipe('en-US')
  displayedColumns: string[] = ['name', 'code', 'date', 'project', 'actions'];
  dataSource = new MatTableDataSource<Researcher>();
  //researcherForm = new FormGroup({
  //  researcherNameControl: new FormControl('', [Validators.required]),
  //});
  researcherForm = new FormGroup({
    inputGameLanguage: new FormControl('', [Validators.required]),
    inputGameClass: new FormControl('', [Validators.required]),
    inputGameCase: new FormControl('', [Validators.required]),
  });

  constructor(public systemService: SystemService,
              public userService: UserService,
              public modalService: BsModalService,
              public cdr: ChangeDetectorRef,
              private route: ActivatedRoute,
              private router: Router) {
                sessionStorage.removeItem('current_page');
                sessionStorage.setItem('current_page', 'admin');
               }

  ngOnInit(): void {
    this.getResearchers();
  }

  errorModal(template: TemplateRef<any>, message) {
    this.errorMessage = message;
    this.modalRef = this.modalService.show(template, {
      ariaDescribedby: 'my-modal-description',
      ariaLabelledBy: 'my-modal-title'
    });
    setTimeout(function() {
      this.modalRef.hide();
    }.bind(this),5000);
  }

  addResearcher(project: Project): void {
    let name = project.name.trim();
    if (!name) { return; }
      this.userService.addResearcher(project)
                       .subscribe(res => {
                         if (res['isOK'] == false && res['err'] == 'unique'){
                           this.errorModal(this.errorTemplate, 'Project exists.')
                         }

                         this.getResearchers();});
  }

  getResearchers(): void {
  this.userService.getResearchers()
    .subscribe((data: Researcher[])=>
          { setTimeout(() => {
              this.allResearchers= data;
              this.dataSource = new MatTableDataSource(this.allResearchers);
              this.cdr.detectChanges();
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
          })});
  }

  openModal(template: TemplateRef<any>, errortemplate: TemplateRef<any>) {
    this.mode = 'save';
    this.errorTemplate = errortemplate;
    if (this.researcherForm.invalid) {
      this.researcherForm.get('inputGameLanguage').markAsTouched();
      this.researcherForm.get('inputGameClass').markAsTouched();
      this.researcherForm.get('inputGameCase').markAsTouched();
      return;
    }else{
      if (this.researcherForm.get('inputGameLanguage').value['code'] === 'German'){
        this.projectName =  this.researcherForm.get('inputGameLanguage').value['de']+'_'+
                            this.researcherForm.get('inputGameClass').value['de']+'_'+
                            this.researcherForm.get('inputGameCase').value['de'];
      }else{
        this.projectName =  this.researcherForm.get('inputGameLanguage').value['en']+'_'+
                            this.researcherForm.get('inputGameClass').value['en']+'_'+
                            this.researcherForm.get('inputGameCase').value['en'];
      }


      this.project = {
        name: this.projectName,
        language: this.langs[this.researcherForm.get('inputGameLanguage').value['code']],
        class:this.researcherForm.get('inputGameClass').value['code'],
        case:this.researcherForm.get('inputGameCase').value['code'],
        code: Math.random().toString(36).slice(-8),
        date: this.datePipeEn.transform(new Date()).toString(),
        isActive:true
      }
      this.message = 'Do you confirm '+ this.projectName +' to '+this.mode+'?'
      this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
      this.uniqueError = ''
    }
  }

  confirm(): void {
    this.message = 'Confirmed!';
    if (this.mode == 'save'){
      this.addResearcher(this.project);
      this.researcherForm.reset();
      this.researcherForm.setErrors({ valid: true });
    }
    if (this.mode == 'delete'){
      this.userService.deleteProject(this.modalDeleteObject)
                       .subscribe(project => {
                         console.log(project);
                         this.getResearchers();
                       });
    }
    this.modalRef.hide();
  }

  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }

  deleteModal(template: TemplateRef<any>, project): void{
    this.modalDeleteObject = project;
    this.mode = 'delete';
    this.message = 'Do you confirm '+ project.name+' to '+this.mode+'?';
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  routeProject(project){
    sessionStorage.removeItem('onlineReseacher');
    const researcher: LoginResearcher = {
       name: project.name,
       code: project.code,
     }
     if (researcher.code){
       this.userService.loginResearcher(researcher)
       .subscribe(researcher => {
           if (researcher) {
             console.log(researcher);

             sessionStorage.setItem('onlineReseacher',JSON.stringify(researcher));
             this.router.navigate(['/settings']);

           }else{
             console.log('Researcher does not exist or wrong name and code.');
           }
         });
       }
    }

}
