import { Component, SecurityContext } from '@angular/core';
import { UserService } from './user.service';
import { SystemService } from './system.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import  *  as  exampleJSON  from  '../assets/examples.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'WordGuess';
  playerName:string;
  isPalyer: boolean;
  language:string;
  page:string;
  isGameDesc:boolean = false;
  gameDesc:string;
  fileUrl;

  constructor(public userService: UserService,
              public systemService: SystemService,
              private route: ActivatedRoute,
              private router: Router,
              private mediaObserver: MediaObserver,
              private sanitizer: DomSanitizer) {
              }
  ngOnInit(): void {
    sessionStorage.setItem('isGameDesc', this.isGameDesc.toString());
    const blob = new Blob([JSON.stringify(exampleJSON['default'])], { type: "data:text/json;charset=UTF-8" });
    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  ngDoCheck() {
    this.checkPlayer();
    this.page = sessionStorage.getItem('current_page');
    this.isGameDesc = JSON.parse(sessionStorage.getItem('isGameDesc'));
    this.gameDesc = sessionStorage.getItem('gameDesc');
  }

  goPlayerProfile():void{
    if(this.userService.isLoggedIn()){
      this.router.navigate(['/player']);
    }
  }
  goGameDecision():void{
    this.router.navigate(['/decision']);
  }

  logOut(): void{
    this.userService.logout();
    sessionStorage.setItem('isGameDesc', 'false');
    this.isGameDesc = false;
  }

  checkPlayer(): void{
    if (this.userService.isLoggedIn()){
      this.isPalyer = true;
      this.playerName = JSON.parse(sessionStorage.getItem('access_token')).name;
    }else{
      this.isPalyer = false;
      this.playerName='';
    }
  }

  changeLanguge(selectedLan){
    this.systemService.systemLanguage = selectedLan;
  }
}
