import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { ResearcherLoginComponent } from './researcher-login/researcher-login.component';
import { ResearcherComponent } from './researcher/researcher.component';
import { PlayerComponent } from './player/player.component';
import { DecisionComponent } from './decision/decision.component';
import { GameComponent } from './game/game.component';
import { ProfileComponent } from './profile/profile.component';
const routes: Routes = [
  {path: 'admin', component: AdminComponent},
  {path: 'project', component: ResearcherLoginComponent},
  {path: 'settings', component: ResearcherComponent},
  {path: 'decision', component: DecisionComponent},
  {path: 'game', component: GameComponent},
  {path: 'player', component: ProfileComponent},
  {path: '', component: PlayerComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
