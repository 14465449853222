// We use this class for keeping the system inforolesrmation like language
import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders, HttpParams } from '@angular/common/http';
import { Researcher, LoginResearcher, PlayerRegisteration, OnlinePlayer, Project, GameSettings} from './data';
import { DatePipe } from '@angular/common';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  activeGameList: Researcher [] = [];
  headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json')
  httpOptions = {headers: this.headers};
  playerUri = '/wordguess_gameserver/player';
  researcherUri = '/wordguess_gameserver/researcher'
  constructor(private http: HttpClient,
              private route: ActivatedRoute,
              private router: Router) {
               }


  getResearchers():Observable<Researcher[]>{
    const researcherObservable = this.http.get<Researcher[]>(`${this.researcherUri}/getAll`, {});
    return researcherObservable;
  }

  loadGames(){
   let games = this.getResearchers()
     games.subscribe((data: Researcher[])=>{
       setTimeout(() => {
          this.activeGameList = data
      });
   });
  }

  addResearcher(project: Project): Observable<Project> {
    return this.http.post<Project>(`${this.researcherUri}/add`, project, this.httpOptions);
  }

  loginResearcher(researcher: LoginResearcher): Observable<any> {
    let params = new HttpParams();
    params = params.append("rc", researcher.code.toString());
    params = params.append("rn", researcher.name.toString());
    return this.http.get(`${this.researcherUri}/get`, {params: params})
  }

  deleteProject(project: Project): Observable<any> {
    return this.http.post<any>(`${this.researcherUri}/delete`, project, this.httpOptions);
  }

  updateProject(project: Project): Observable<any> {
    return this.http.post<any>(`${this.researcherUri}/update`, project, this.httpOptions);
  }


  addPlayer(player: PlayerRegisteration): Observable<any> {
    return this.http.post<any>(`${this.playerUri}/add`, player, this.httpOptions);
  }

  updatePlayer(player: OnlinePlayer): Observable<any> {
    return this.http.post<any>(`${this.playerUri}/update`, player, this.httpOptions);
  }

  deletePlayer(player: OnlinePlayer): Observable<any> {
    return this.http.post<any>(`${this.playerUri}/delete`, player, this.httpOptions);
  }

  login(username: string) {
    return this.http.post<any>(`${this.playerUri}/authenticate`, {name:username})
  }

  getAccessToken() {
    return sessionStorage.getItem('access_token');
  }

  isLoggedIn(): boolean {
    return JSON.parse(sessionStorage.getItem('isLoggedIn'));
  }

  setPlayerLoggedIn(bool, res):void{
    if (bool){
      sessionStorage.removeItem('access_token')
      sessionStorage.setItem('access_token', JSON.stringify(res.token[0]));
      sessionStorage.setItem('isLoggedIn', "true");
      sessionStorage.setItem('playerStatus', 'playWithAccount')

    }else{
      sessionStorage.setItem('access_token', "{}");
      sessionStorage.setItem('isLoggedIn', "false");
      sessionStorage.setItem('playerStatus', 'playWithoutAccount')
    }
  }

  logout() {
    this.setPlayerLoggedIn(false, {});
    if (JSON.parse(sessionStorage.getItem('isLoggedIn')) == false) {
      sessionStorage.setItem('access_token', "{}");
      sessionStorage.setItem('isLoggedIn', "false");
      sessionStorage.setItem('playerStatus', 'playWithoutAccount')
      this.router.navigate(['']);
    }
  }

}
