import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient,  HttpHeaders, HttpParams } from '@angular/common/http';
import { Data, Pair, Diff, RawData, SystemLanguage, GermanLan } from './data';
import { map } from 'rxjs/operators';
import nativeLangsEN from 'src/assets/language_codes_en.json';
import nativeLangsDE from 'src/assets/language_codes_de.json';
import nativeLangs from 'src/assets/code_de_en_langs.json';
import { BehaviorSubject, Observable } from 'rxjs';
import { SystemService } from './system.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  gameData: Data[];
  isDataReady: boolean = false;
  actionsUri = '/wordguess_gameserver/actions'
  headers = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Accept', 'application/json')
  httpOptions = {headers: this.headers};
  constructor(private http: HttpClient, public systemService: SystemService) { }

  getNativeLangsOld(): SystemLanguage[]{
    let nativeLanguages: SystemLanguage[] = [];
    nativeLangsEN.forEach(function (lan) {
      let german: string;
      nativeLangsDE.forEach(function (dlan) {
          if (dlan.alpha2 == lan.alpha2){
            german = dlan.name;
            let l:SystemLanguage = {
              en: lan.English,
              code: lan.alpha2,
              de: german
            }
            nativeLanguages.push(l);
          }
      });
    });
    return nativeLanguages;

  }

  getNativeLangs(): SystemLanguage[]{
    let nativeLanguages: SystemLanguage[] = [];
    nativeLangs.forEach(function (lan) {
        let l:SystemLanguage = {
            en: lan.en,
            code: lan.code,
            de: lan.de
        }
        nativeLanguages.push(l);
    });
    return nativeLanguages;

  }


  transformData(game, guessNumber, difficultyLevel): void{
    console.log(game);
    console.log(guessNumber);

    sessionStorage.setItem('game_settings', JSON.stringify({
      playerGuessNumber:guessNumber,
      playerDifficultyLevel:difficultyLevel,
      contextNumber:game.contextNumber,
      description:game.description,
      hintOrder:game.hintOrder,
      name:game.name,
      targetOrder:game.targetOrder,
      contextOrder:game.contextOrder,
      gameCode:game.gameCode
    }));
    //sessionStorage.setItem('game_code', game.gameCode);
    const groupByTarget = JSON.parse(game.data);
    console.log(groupByTarget);
    let keys = Object.keys(groupByTarget);
    let packedDataList: Data[] = [];
    let idx: number;
    for (var _i = 0; _i < guessNumber; _i++) {

      //Target selection
      if (game.targetOrder == 'random'){
        idx = Math.floor(Math.random() * keys.length);
      }else{
        idx = _i
      }
      console.log(keys);

      let targetKey = keys[idx];
      console.log(targetKey);

      if (targetKey.length > 2){
          let contextList = groupByTarget[targetKey];
          // Context Cue Selection
          let cue:any;
          let cueScore = game.hintOrder
          if (cueScore == 'highest'){
            cue = contextList.reduce((prev, current) => (prev.score > current.score) ? prev : current)
          }else if(cueScore == 'lowest'){
            cue = contextList.reduce((prev, current) => (prev.score < current.score) ? prev : current)
          }else if(cueScore == 'random'){
            cue = contextList[Math.floor(Math.random() * contextList.length)];
          }else{cue = {context:'', score:0}}
          contextList = contextList.filter(obj => obj.context !== cue.context);

          //Context order
          let context: any[];
          let contextOrder = game.contextOrder
          if (contextOrder == 'random'){
            context = this.getRandom(contextList, game.contextNumber);
          }else{
            context = contextList.slice(0, game.contextNumber)
          }
          const targetCue: string = this.getTargetCue(targetKey, difficultyLevel);
          const packedData: Data = {target:targetKey, targetCue:targetCue, contextCue:cue, context:context};
          packedDataList.push(packedData);
          keys.splice(idx,1);
          delete keys[targetKey];
       }else{
          _i--;
          delete keys[targetKey];
        }
    }

    if (packedDataList){
      this.gameData = packedDataList;
      this.isDataReady = true;
      sessionStorage.setItem('data', JSON.stringify(this.gameData));
    }else{
      this.isDataReady = false;
    }
  }

  getTargetCue(targetKey, difficultyLevel){
    let medium = this.getMediumDiff(targetKey);
    let easy = this.getEasyDiff(targetKey);
    const targetCue: Diff = {m:medium, e:easy};
    if (difficultyLevel == 'e'){ return targetCue.e; }
    else if (difficultyLevel == 'm'){ return targetCue.m; }
    else {return ''}
  }

  getEasyDiff(targetKey){
    let clueStr =' ';
    let diffCounter = 1;
    let targetArray = targetKey.split('');
    let clueKeyIdx1 = Math.floor(Math.random() * targetArray.length) + 1;
    for (let i of targetArray) {
      if (diffCounter==clueKeyIdx1){
        clueStr +=i+' ';
      }
      else{
        clueStr +='_ ';
      }
      diffCounter +=1;
    }
    return clueStr
  }

  getMediumDiff(targetKey){
    let clueStr ='';
    for (let i of targetKey) {
      clueStr = clueStr +'_ '
    }
    return clueStr;
  }

  getRandom(arr, n) {
      var result = new Array(n),
          len = arr.length,
          taken = new Array(len);
      console.log(len, n);

      if (n > len)
          throw new RangeError("getRandom: more elements taken than available");
      while (n--) {
          var x = Math.floor(Math.random() * len);
          result[n] = arr[x in taken ? taken[x] : x];
          taken[x] = --len in taken ? taken[len] : len;
      }
      return result;
  }

  addActions(actions: any):Observable<any>{
    return this.http.post<any>(`${this.actionsUri}/add`, actions);
  }

  getPlayerActions(player: any):Observable<any>{
    return this.http.post<any>(`${this.actionsUri}/getPlayerActions`, player);
  }

  getProjectActions(code: string):Observable<any>{

    return this.http.post<any>(`${this.actionsUri}/getProjectActions`,  {code:code});
  }

}
