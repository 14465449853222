<ng-template #template>

  <div class="modal-body text-left">
    <p>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalName}}: {{modalPlayer.name}}</p>
    <p>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalGender}}: {{modalPlayer.gender}}</p>
    <p>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalAge}}: {{modalPlayer.age}}</p>
    <p>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalLanguage}}: {{modalPlayer.nativeLanguage}}</p>
    <p>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].confirmQuestion}}</p>
    <button type="button" class="btn btn-default" (click)="confirm()">
      {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalYesButton}}</button>
    <button type="button" class="btn btn-primary" (click)="decline()">
      {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalNoButton}}
    </button>
  </div>
</ng-template>

<ng-template #errortemplate>
  <div class="modal-header">
    <h6 class="modal-title pull-left" id="my-modal-title">
      {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].modalAttention}}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div id="my-modal-description">
      {{errorMessage}}
    </div>
  </div>
</ng-template>

<div *ngIf="isLogin; then thenBlock else elseBlock"></div>
<ng-template #thenBlock style="text-align:center">
  <mat-card >
    <mat-card-content>
      <form [formGroup]="loginForm" (ngSubmit)="submitLogin(loginForm.value, errortemplate)">
        <p>
          <mat-form-field >
            <input type="text"
              matInput placeholder={{systemService.screen2Language[systemService.screen][systemService.systemLanguage].loginPlayerPlaceholder}}
              formControlName="inputPlayerNameLogin">
          </mat-form-field>
        </p>
        <p *ngIf="error" class="error">
          {{ error }}
        </p>
        <div style="text-align:right;">
          <button type="submit"
           id="loginButton"
          mat-button>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].playWithAccountButton}}</button>
        </div>
      </form>
    </mat-card-content>
    <div style="text-align:right;">
      <button  style="font-size: 12px !important;"
      (click)="goRegisterPage()"
      mat-button>
      {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].createAPlayerAccount}}
      </button>
        </div>
        <div style="text-align:right;">
      <button  style="font-size: 12px !important;"
      (click)="skipLogin()"
      #tooltip="matTooltip"
      matTooltip="Play without registration"
      mat-button>
      {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].playWithoutAccount}}
      </button>
    </div>
  </mat-card>
</ng-template>

<ng-template #elseBlock style="text-align:center">
  <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card>
      {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].createAPlayerAccount}}
      <form class="example-form" [formGroup]="registerationForm" (ngSubmit)="openFileModal(template, errortemplate, registerationForm.value)">
        <mat-card-content>
          <mat-form-field hintLabel="{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].hintNamePlayer}}" class="example-full-width">
            <input matInput placeholder="{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].namePlayerLabel}}" formControlName="inputPlayerName">
          </mat-form-field>
          <br>
          <mat-form-field class="example-full-width" style="margin: 10 0 0 0 !important;">
            <mat-label>
              {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].genderPlayerLabel}}
            </mat-label>
            <mat-select [(value)]="selectedGender" formControlName="inputPlayerGender">
              <mat-option [value]="gender" *ngFor="let gender of systemService.Genders">
                {{gender[systemService.systemLanguage]}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>
              {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].agePlayerLabel}}
            </mat-label>
            <mat-select [(value)]="selectedAge" formControlName="inputPlayerAge">
              <mat-option [value]="age" *ngFor="let age of systemService.Ages">
                {{age[systemService.systemLanguage]}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>
              {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].nativeLanguagePlayerLabel}}
            </mat-label>
            <mat-select [(value)]="selectedNativeLanguage" formControlName="inputPlayerNativeLanguage">
              <mat-option [value]="nlang" *ngFor="let nlang of nativeLanguageArray">
                {{nlang[systemService.systemLanguage]}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-content>
        <div style="text-align:right;">
          <button id="registerButton"
          type="submit"
          mat-button>
          {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].createAPlayerAccount}}
          </button>
        </div>
        <div style="text-align:right; font-size: 12px !important;">
          <button style="font-size: 12px !important;"
            (click)="goLoginPage()"
            mat-button>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].backButton}}
          </button>
        </div>
      </form>
    </mat-card>
  </div>
</ng-template>
