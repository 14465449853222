import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../user.service';
import { DataService } from '../data.service';
import { BsModalService, BsModalRef, ModalDirective } from 'ngx-bootstrap/modal';
import { FormControl, Validators, FormGroup, FormArray} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatGridListModule } from '@angular/material/grid-list';
import { DatePipe } from '@angular/common';
import { SystemService } from '../system.service';
import { MatTableDataSource } from '@angular/material/table';
import { Data, Action, Pair, TargetPair, OnlinePlayer } from '../data';
@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GameComponent implements OnInit {
  isGame: boolean = true;
  isTarget:boolean = false;
  dataList: Data[];
  targetNumber:number;
  data: Data;
  roundContext: Pair[];
  contextArray: string[] = [];
  targetCue: string;
  actions: Action[] = [];
  target: string;
  score: number = 0;
  n:number = 1 ;
  contextCue: string;
  totalScore: number = 0;
  targetCounter: number = 0;
  cue: string;
  wrongAssumption: string[] = [];
  pastTargetScorePair: TargetPair[] = [];


  datePipeEn: DatePipe = new DatePipe('en-US')
  contextModal:string;
  template: TemplateRef<any>;
  modalRef: BsModalRef;

  parsedPlayer: OnlinePlayer;
  screen:string;
  guessButton:string = "Guess";
  skipButton:string = "Skip"
  guessIcon:string = ''
  skipIcon:string = ''
  breakpoint: number;
  fontStyle:string = "font-size: calc(1.5em);font-weight: bold;"
  inputGroupStyle:string = "input-group";
  modalStyle:string;
  modalColor:string = 'red';
  cardStyle:string;
  gameOver:string = 'Game Over!';
  gameAgain:string = 'Again!';
  decisionAgain: string = 'New Game!'
  displayedColumns: string[] = ['target', 'score'];
  dataSource = new MatTableDataSource<TargetPair>();
  constructor(public userService: UserService,
              public dataService: DataService,
              public systemService: SystemService,
              private route: ActivatedRoute,
              private router: Router,
              private modalService: BsModalService,) {
                sessionStorage.removeItem('current_page');
                sessionStorage.setItem('current_page', 'game');
               }

  ngOnInit(): void {
    if(this.dataService.gameData) {
      this.dataList = this.dataService.gameData;
      this.screen = this.systemService.screen;
      this.breakpoint = this.systemService.breakpoint;
      this.fontStyle = this.systemService.fontStyle;
      this.inputGroupStyle = this.systemService.inputGroupStyle;
      this.guessButton = this.systemService.guessButton;
      this.skipButton = this.systemService.skipButton;
      this.guessIcon = this.systemService.guessIcon;
      this.skipIcon = this.systemService.skipIcon;
      this.modalStyle = this.systemService.modalStyle;
      this.cardStyle = this.systemService.cardStyle;

      this.dataList = this.dataService.gameData;
      this.targetNumber = this.dataList.length;
      this.dataPipe();
    } else {
        this.router.navigate(['/decision']);
    }
  }

  parsePlayer(){
    let player = JSON.parse(sessionStorage.getItem('access_token'));
    this.parsedPlayer = {
      _id:player['_id'],
      name:player.name,
      gender: JSON.parse(player.gender).code,
      age: JSON.parse(player.age).code,
      nativeLanguage: JSON.parse(player.nativeLanguage).code,
      date: player.date
    }
  }

  dataPipe(): void{
    if (this.targetCounter == this.targetNumber){
      this.dataSource = new MatTableDataSource(this.pastTargetScorePair);
      this.isGame = false;
      if (sessionStorage.getItem('playerStatus') == 'playWithAccount'){
        this.saveData();
      }else{
      }
    }else{
      this.data = this.dataList[this.targetCounter];
      this.targetCounter = this.targetCounter + 1;
      this.cue = this.data.contextCue.context;
      this.targetCue = this.data.targetCue;
      this.target = this.data.target;
      this.roundContext = this.data.context;
      this.contextArray = []
      this.wrongAssumption = []
      this.totalScore = this.totalScore + this.score;
      this.score = 20;
      if (this.actions.filter(function(x)
            {return x.action=='game_start'}).length < this.targetNumber){
            this.actions.push({action: 'game_start', token: this.target,
            time: this.datePipeEn.transform(new Date(), 'longTime'),
            extraContextCue: this.contextCue, targetCue: this.targetCue});
      }
    }
  }

  contextClick(template: TemplateRef<any>, event, id):void{
    var str = 'obj_'+id;
    const el: HTMLElement = document.getElementById(str);
    el.textContent = id;
    if(this.score > 1){
        this.targetFocus();
        if (this.contextArray.indexOf(str) == -1){
            this.score = this.score -1;
            this.contextArray.push(str);
            this.actions.push({
              action: 'context_click',
              token: id,
              time: this.datePipeEn.transform(new Date(), 'longTime'),
              extraContextCue: '-None-', targetCue: '-None-'
            });}
    }else{
      this.skipClick(template)
    }
  }

  guessClick(template: TemplateRef<any>):void{
    this.template = template;
    const targetInput = (<HTMLInputElement>document.
                        getElementById('targetInput')).value;
    if (targetInput == ''){this.targetFocus();}
    else{
      if (targetInput.toLowerCase() == this.target.toLowerCase()){
        if (this.systemService.systemLanguage=='de'){
          let x = this.target[0].toUpperCase() + this.target.slice(1)
          this.showModal(x, 'green');
        }
        if (this.systemService.systemLanguage=='en'){
          let x = this.target
          this.showModal(x, 'green');
        }
      }else{
        this.wrongAssumption.push(targetInput);
        this.actions.push({
          action: 'wrong_assumption',
          token: targetInput,
          time: this.datePipeEn.transform(new Date(), 'longTime'),
          extraContextCue: '-None-',
          targetCue: '-None-'});
          if (this.score > 3){
            this.targetFocus();
            this.score = this.score - 3;
          }else{
            this.skipClick(template)
          }
      }
      (<HTMLInputElement>document.getElementById('targetInput')).value = '';
    }
  }

  skipClick(template: TemplateRef<any>): void{
    this.template = template;
    this.afterSkip();
  }

  targetFocus(){
    if (this.systemService.screen == 'lg' || this.systemService.screen =='xl'){
      const elInput: HTMLElement = document.getElementById('targetInput');
      elInput.focus();
    }
  }

  saveData():void{
    this.parsePlayer();
    let actionsDB = {
      gameCode: JSON.parse(sessionStorage.getItem('game_settings')).gameCode,
      playerName: JSON.parse(sessionStorage.getItem('access_token')).name,
      playerId: JSON.parse(sessionStorage.getItem('access_token'))._id,
      player: JSON.stringify(this.parsedPlayer),
      decision: sessionStorage.getItem('game_settings'),
      game: JSON.stringify(this.pastTargetScorePair),
      actions: JSON.stringify(this.actions),
      date: this.datePipeEn.transform(new Date()).toString(),
      totalScore:this.getTotalScore().toString(),
    }
    this.dataService.addActions(actionsDB)
      .subscribe(res => {
        if (res.result){

        }else{

        }
    });
  }

  showModal(message, caseColor) {
    this.modalColor = caseColor;
    this.contextModal = message;
    this.modalRef = this.modalService.show(this.template, {class: this.modalStyle});
    if (caseColor == 'red'){
      setTimeout(function() {
        this.modalRef.hide();
        this.contextModal = ''
        this.targetFocus();
     }.bind(this),1000);
   }else{
     setTimeout(function() {
       this.modalRef.hide();
       this.contextModal = '';
       this.afterCorrectGuess();
    }.bind(this),1000);
   }
  }

  afterCorrectGuess(){
    this.pastTargetScorePair.push({target:this.target, score: this.score});
    this.actions.push({action: 'correct_guess', token: this.target,
     time: this.datePipeEn.transform(new Date(), 'longTime'),
     extraContextCue: '-None-', targetCue: '-None-'});
    this.dataPipe();
    this.targetFocus();
  }

  afterSkip(){
    (<HTMLInputElement>document.getElementById('targetInput')).value = '';
    this.score = 0;
    if (this.systemService.systemLanguage=='de'){
      let x = this.target[0].toUpperCase() + this.target.slice(1)
      this.showModal(x, 'red');
    }
    if (this.systemService.systemLanguage=='en'){
      let x = this.target
      this.showModal(x, 'red');
    }
    this.pastTargetScorePair.push({target:this.target, score:this.score});
    this.actions.push({action: 'skip', token: '',
    time: this.datePipeEn.transform(new Date(), 'longTime'),
    extraContextCue: '-None-', targetCue: '-None-'});
    this.dataPipe();
    this.targetFocus();
  }

  decisionAgainClick():void{
    this.router.navigate(['/decision']);
  }

  gameAgainClick(): void{
    this.isGame = true;
    this.targetCounter = 0;
    this.pastTargetScorePair = []
    this.dataPipe();
  }

  getTotalScore() {
    return this.pastTargetScorePair
    .map(t => t.score).reduce((acc, value) => acc + value, 0);
  }

}
