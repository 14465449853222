

  <mat-card class="box">
    <form class="example-form" [formGroup]="gameSelectionForm" (ngSubmit)="startGame(gameSelectionForm.value)">
        <mat-form-field hintLabel="{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].hintGameDecision}}" style="padding: 5px !important; width: 250px !important;"  class="example-full-width">
          <mat-label>
            {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameDecisionLabel}}
          </mat-label>
          <mat-select [(value)]="selectedGameName" formControlName="inputGameName">
            <mat-option [value]="game" *ngFor="let game of Games">
              {{game.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field hintLabel="{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].hintTargetNumberDecision}}" style="padding: 5px !important; width: 250px !important;"  class="example-full-width">
          <mat-label>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].targetNumberDecisionLabel}}
          </mat-label>
          <mat-select [(value)]="selectedGuessNumber" formControlName="inputGuessNumber">
            <mat-option [value]="guessNumber" *ngFor="let guessNumber of GuessNumbers">
              {{guessNumber}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field hintLabel="{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].hintDifficultyLevelDecision}}"
          style="padding: 5px !important; width: 250px !important;" class="example-full-width">
          <mat-label>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].difficultyLevelDecisionLabel}}
          </mat-label>
          <mat-select [(value)]="selectedDifficultyLevel" formControlName="inputDifficultyLevel">
            <mat-option [value]="difficultyLevel.code" *ngFor="let difficultyLevel of DifficultyLevels[systemService.systemLanguage]">
              {{difficultyLevel.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      <div class="button">
        <button id="startButton" type="submit" mat-button>
          {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].startGame}}
        </button>
      </div>
    </form>
    <div class="button">
      <button style="font-size: 12px !important;"
      (click)="backToLogin()"
      mat-button>
      {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].backButton}}
    </button>
  </div>
  </mat-card>
