import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, Validators, FormGroup, FormArray} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import 'rxjs/add/operator/map';
import { DatePipe } from '@angular/common';
import { SystemService } from '../system.service';
import { UserService } from '../user.service';
import { DataService } from '../data.service';
import { Researcher, TargetPair, OnlinePlayer, SystemLanguage } from '../data';
import { Router, ActivatedRoute } from '@angular/router';

export class ActionTable{
  gameName:string;
  totalScore:string;
  date:string;
  game: TargetPair[];
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  displayedColumns: string[] = ['gameName', 'totalScore', 'date'];
  dataSource = new MatTableDataSource<any>();


  gameDisplayedColumns: string[] = ['target', 'score'];
  gameDataSource = new MatTableDataSource<any>();

  datePipeEn: DatePipe = new DatePipe('en-US')
  nativeLanguageArray: SystemLanguage[];
  player: OnlinePlayer;
  modalPlayer: OnlinePlayer;
  nonObjectPlayer: OnlinePlayer;
  player_id: string;
  isUpdate: boolean = false;
  registerationForm = new FormGroup({
    inputPlayerName: new FormControl('', [Validators.required]),
    inputPlayerAge: new FormControl('', [Validators.required]),
    inputPlayerGender: new FormControl('', [Validators.required]),
    inputPlayerNativeLanguage: new FormControl('', [Validators.required]),
  });
  upToDatePlayer: OnlinePlayer;
  modalShowPlayer: OnlinePlayer;
  selectedAge:string;
  selectedGender:string;
  selectedNativeLanguage:string;
  registerationModalMessage:string;
  errorMessage:string;
  isConfirm:boolean = false;
  errorTemplate: TemplateRef<any>;
  modalRef: BsModalRef;
  modalMode: string;
  constructor(public systemService: SystemService,
              private dataService: DataService,
              private userService: UserService,
              private modalService: BsModalService,
              private router: Router) {
                sessionStorage.removeItem('current_page');
                sessionStorage.setItem('current_page', 'profile');
              }

  ngOnInit(): void {
    this.setCard();
  }

  setCard():void{
    this.nativeLanguageArray = this.dataService.getNativeLangs();
    let onlinePlayer = JSON.parse(sessionStorage.getItem('access_token'));
    this.player = {
      _id:onlinePlayer._id,
      age: onlinePlayer.age,
      gender: onlinePlayer.gender,
      name: onlinePlayer.name,
      nativeLanguage: onlinePlayer.nativeLanguage,
      date: onlinePlayer.date
    }
    this.modalPlayer = {
      _id:onlinePlayer._id,
      age: JSON.parse(onlinePlayer.age),
      gender: JSON.parse(onlinePlayer.gender),
      name: onlinePlayer.name,
      nativeLanguage:JSON.parse(onlinePlayer.nativeLanguage),
      date: onlinePlayer.date
    }
    this.nonObjectPlayer = {
      _id:onlinePlayer._id,
      age: JSON.parse(onlinePlayer.age)[this.systemService.systemLanguage],
      gender: JSON.parse(onlinePlayer.gender)[this.systemService.systemLanguage],
      name: onlinePlayer.name,
      nativeLanguage:JSON.parse( onlinePlayer.nativeLanguage)[this.systemService.systemLanguage],
      date: onlinePlayer.date
    }
    this.getActions()
  }

  getActions(): void {
    this.dataService.getPlayerActions(this.nonObjectPlayer)
    .subscribe((data: any)=>
          { setTimeout(() => {
            if (data.result){
              let playersActionSet: ActionTable[] = []
              for (let entry of data.token) {
                  let decision = JSON.parse(entry['decision']);
                  let game = JSON.parse(entry['game']);
                  playersActionSet.push({
                    gameName: decision.name,
                    totalScore: entry.totalScore,
                    date: entry.date.split(",",)[0],
                    game: game,
                  })
              }

              this.dataSource = new MatTableDataSource(playersActionSet);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
            }
          })});
  }

  getActionsGame(event):void{
      this.gameDataSource = new MatTableDataSource(event.game);
  }
  back():void{
    this.router.navigate(['/decision']);
  }

  update():void{
    this.isUpdate = true;
    const g = JSON.parse(this.player.gender);
    const a = JSON.parse(this.player.age);
    const nl = JSON.parse(this.player.nativeLanguage);
    this.registerationForm.controls['inputPlayerName'].setValue(this.player.name);
    const toSelectGender =  this.systemService.Genders.find(c => c.code == g.code);
    this.registerationForm.controls['inputPlayerGender'].setValue(toSelectGender);
    const toSelectAge =  this.systemService.Ages.find(c => c.code == a.code);
    this.registerationForm.controls['inputPlayerAge'].setValue(toSelectAge);
    const toSelectNativeLanguage =  this.nativeLanguageArray.find(c => c.code == nl.code);
    this.registerationForm.controls['inputPlayerNativeLanguage'].setValue(toSelectNativeLanguage);
  }

  errorModal(template: TemplateRef<any>, message) {
    this.errorMessage = message;
    this.modalRef = this.modalService.show(template, {
      ariaDescribedby: 'my-modal-description',
      ariaLabelledBy: 'my-modal-title'
    });
    setTimeout(function() {
      this.modalRef.hide();
      this.errorMessage = ''
    }.bind(this),2000);
  }

  updatePlayer(template: TemplateRef<any>, errortemplate: TemplateRef<any>, registerationFormTemplate):void{
    this.errorTemplate = errortemplate;
    if (this.registerationForm.invalid) {
        this.registerationForm.get('inputPlayerAge').markAsTouched();
        this.registerationForm.get('inputPlayerGender').markAsTouched();
        this.registerationForm.get('inputPlayerNativeLanguage').markAsTouched();
        return;
     }else{
       this.upToDatePlayer = {
          _id:JSON.parse(sessionStorage.getItem('access_token'))['_id'],
          name: registerationFormTemplate.inputPlayerName,
          gender: JSON.stringify(registerationFormTemplate.inputPlayerGender),
          age: JSON.stringify(registerationFormTemplate.inputPlayerAge),
          nativeLanguage: JSON.stringify(registerationFormTemplate.inputPlayerNativeLanguage),
          date:this.datePipeEn.transform(new Date()).toString()
        }
        this.modalShowPlayer = {
           _id:JSON.parse(sessionStorage.getItem('access_token'))['_id'],
           name: registerationFormTemplate.inputPlayerName,
           gender: registerationFormTemplate.inputPlayerGender,
           age: registerationFormTemplate.inputPlayerAge,
           nativeLanguage: registerationFormTemplate.inputPlayerNativeLanguage,
           date:this.datePipeEn.transform(new Date()).toString()
         }

         this.modalMode= 'update';
        this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
      }
  }

  login(playerName:string):void{
    this.userService.login(playerName)
      .subscribe((res: any) => {
          if (res.result){
            this.userService.setPlayerLoggedIn(res.result, res);
            this.setCard();
            this.isUpdate = false;
            this.userService.isLoggedIn()
          }else{
            this.router.navigate(['']);
          }
      });
  }

  deletePlayer(template: TemplateRef<any>, errortemplate: TemplateRef<any>):void{
    this.errorTemplate = errortemplate;
    this.upToDatePlayer = {
          _id:JSON.parse(sessionStorage.getItem('access_token'))['_id'],
          name: JSON.parse(sessionStorage.getItem('access_token'))['name'],
          gender:  JSON.parse(sessionStorage.getItem('access_token'))['gender'],
          age: JSON.parse(sessionStorage.getItem('access_token'))['age'],
          nativeLanguage:  JSON.parse(sessionStorage.getItem('access_token'))['nativeLanguage'],
          date:this.datePipeEn.transform(new Date()).toString()
    }
    this.modalShowPlayer = {
      _id:JSON.parse(sessionStorage.getItem('access_token'))['_id'],
      name: JSON.parse(sessionStorage.getItem('access_token'))['name'],
      gender:  JSON.parse(JSON.parse(sessionStorage.getItem('access_token'))['gender']),
      age:  JSON.parse(JSON.parse(sessionStorage.getItem('access_token'))['age']),
      nativeLanguage:  JSON.parse(JSON.parse(sessionStorage.getItem('access_token'))['nativeLanguage']),
      date:this.datePipeEn.transform(new Date()).toString()
    }
    this.modalMode= 'delete';
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  updateDB():void{
    this.userService.updatePlayer(this.upToDatePlayer)
      .subscribe(res => {
        if (res.result){
          this.player = res.token;
          this.login(this.player.name)
          this.isUpdate = false;
          this.setCard();
          this.errorModal(this.errorTemplate, this.systemService.modalText[this.systemService.systemLanguage].updatedSaved);
        }else{
          this.errorModal(this.errorTemplate, res.message);
          //this.registerationForm.reset();
        }
    });
  }

  deleteDB():void{
    let onlinePlayer = JSON.parse(sessionStorage.getItem('access_token'));
      this.userService.deletePlayer(onlinePlayer)
                             .subscribe(res => {
                               if (res.result === true){
                                 this.userService.logout();
                                 this.router.navigate(['']);
                               }
                             });
  }

  confirm(): void {
    this.isConfirm = true;
    if (this.modalMode == 'update'){
      this.updateDB();
    }
    if (this.modalMode == 'delete'){
      this.deleteDB();
    }
    this.modalMode = '';
    this.modalRef.hide();
  }

  decline(): void {
    this.isConfirm = false;
    //this.registerationForm.reset() instead use load the card back
    this.modalRef.hide();
  }
}
