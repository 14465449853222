import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup} from '@angular/forms';
import { UserService } from '../user.service';
import { SystemService } from '../system.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginResearcher } from '../data';


@Component({
  selector: 'app-researcher-login',
  templateUrl: './researcher-login.component.html',
  styleUrls: ['./researcher-login.component.css']
})
export class ResearcherLoginComponent implements OnInit {
  researcherExistError:string;
  userForm = new FormGroup({
    inputUserName: new FormControl('', [Validators.required]),
    inputUserCode: new FormControl('', [Validators.required]),
  });
  constructor(private systemService: SystemService,
              private userService: UserService,
              private route: ActivatedRoute,
              private router: Router) {
                sessionStorage.removeItem('current_page');
                sessionStorage.setItem('current_page', 'project-login');
               }

  ngOnInit(): void {
    console.log('ResearcherLoginComponent');

  }


  login(){
    sessionStorage.removeItem('onlineReseacher');
    if (this.userForm.invalid) {
      this.userForm.get('inputUserName').markAsTouched();
      this.userForm.get('inputUserCode').markAsTouched();
      this.researcherExistError = ''
      return;
    }
    const researcher: LoginResearcher = {
       name: this.userForm.get('inputUserName').value,
       code: this.userForm.get('inputUserCode').value,
     }
     if (researcher.code){
       this.userService.loginResearcher(researcher)
       .subscribe(researcher => {
           if (researcher) {
             console.log(researcher);

             sessionStorage.setItem('onlineReseacher',JSON.stringify(researcher));
             this.router.navigate(['/settings']);

           }else{
             this.researcherExistError = 'Researcher does not exist or wrong name and code.';
           }
         });
       }
    }

}
