import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { DataService } from '../data.service';
import { FormControl, Validators, FormGroup, FormArray} from '@angular/forms';
import { SystemService } from '../system.service';
import { Data, GameSettings, Researcher } from '../data';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-decision',
  templateUrl: './decision.component.html',
  styleUrls: ['./decision.component.css']
})
export class DecisionComponent implements OnInit {

  isLoggedIn:boolean = false;
  isGameLoaded:boolean = false;
  Games: GameSettings[] = [];
  activeGames: GameSettings[] = [];
  DifficultyLevels: {} = {en:[{name:'Easy', code:'e'}, {name:'Medium', code:'m'}, {name:'Hard', code:'h'}],
                          de:[{name:'Einfach', code:'e'}, {name:'Mittel', code:'m'}, {name:'Schwierig', code:'h'}]};
  GuessNumbers: number[] = [5, 10];
  selectedGameName:string;
  selectedDifficultyLevel:string;
  selectedGuessNumber:number;
  systemLan:string ='de';
  activeGameList: Researcher [] = [];
  gameSelectionForm = new FormGroup({
    inputGameName: new FormControl('', [Validators.required]),
    inputDifficultyLevel: new FormControl('', [Validators.required]),
    inputGuessNumber: new FormControl('', [Validators.required]),
  });
  constructor(public userService: UserService,
              public dataService: DataService,
              public systemService: SystemService,
              private route: ActivatedRoute,
              private router: Router) {
                sessionStorage.removeItem('current_page');
                sessionStorage.setItem('current_page', 'decision');
                this.systemLan = this.systemService.systemLanguage;
               }

  ngOnInit(): void {
    sessionStorage.setItem('isGameDesc', 'false');
    this.Games = [];
    this.selectedGameName = '';
    this.loadGames();

  }

  loadGames(){
    this.userService.getResearchers()
      .subscribe((activeData: Researcher[])=>
            { setTimeout(() => {
              this.systemService.getGames()
                .subscribe((data: any[])=>{
                    setTimeout(() => {
                      for (let element of activeData){
                        for (let entry of data['token']) {
                          if(JSON.parse(entry['researcher'])['language'] === this.systemService.systemLanguage){
                            if (element['code'] === entry['gameCode']){
                              this.Games.push(entry)
                            }
                          }
                        }
                      }
                  this.isGameLoaded = true;
              })
          });
     });
  });
    this.isLoggedIn = JSON.parse(sessionStorage.getItem('isLoggedIn'));
  }
  ngDoCheck() {
    if (this.systemService.systemLanguage != this.systemLan){
      this.gameSelectionForm.reset();
      sessionStorage.setItem('isGameDesc', 'false');
      this.Games = [];
      this.selectedGameName = '';
      this.systemLan = this.systemService.systemLanguage;
      this.loadGames();
    }
    if (this.selectedGameName){
      let g = this.Games.filter(game => game['name'] === this.selectedGameName['name']);
      sessionStorage.setItem('isGameDesc', 'true');
      sessionStorage.setItem('gameDesc', g[0]['description']);
    }
  }

  startGame(gameSelectionForm):void{
    if (gameSelectionForm.invalid) {
      gameSelectionForm.get('inputGameName').markAsTouched();
      gameSelectionForm.get('inputDifficultyLevel').markAsTouched();
      gameSelectionForm.get('inputGuessNumber').markAsTouched();
      return;
    }else{

      if(gameSelectionForm.inputGuessNumber && gameSelectionForm.inputDifficultyLevel){
        this.dataService.transformData(gameSelectionForm.inputGameName,
                                       gameSelectionForm.inputGuessNumber,
                                       gameSelectionForm.inputDifficultyLevel)
          this.router.navigate(['/game']);

      }else{
        gameSelectionForm.get('inputGameName').markAsTouched();
        gameSelectionForm.get('inputDifficultyLevel').markAsTouched();
        gameSelectionForm.get('inputGuessNumber').markAsTouched();
      }

    }
  }
  backToLogin():void{
    this.userService.logout();
    this.router.navigate(['']);
    sessionStorage.setItem('isGameDesc', 'false');
    this.Games = [];
    this.selectedGameName = '';
  }
}
