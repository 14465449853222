<div *ngIf="isGame; then thenBlock else elseBlock"></div>
<ng-template #contextTemplate>
  <div id="modalId" style="background-color:{{modalColor}}">
    <div class="modal-dialog modal-sm">
      <div id="my-modal-description" style="{{fontStyle}}">
        {{contextModal}}
      </div>
    </div>
  </div>
</ng-template>
<ng-template #thenBlock>
<div style="{{cardStyle}} padding: 5px !important;">
    <div class="row justify-content-md-center">
      <div class="col col-lg-3" style="{{fontStyle}}">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameTarget}}
         <span style="{{fontStyle}}" class="badge badge-light">{{targetNumber}}/{{targetCounter}}</span></div>
      <div class="col col-lg-3" style="{{fontStyle}}">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameScore}}
         <span style="{{fontStyle}}" class="badge badge-light">{{score}}</span></div>
      <div class="col col-lg-3" style="{{fontStyle}}">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameTotal}}
        <span style="{{fontStyle}}" class="badge badge-light">{{totalScore}}</span></div>
    </div>

    <div *ngIf="screen==='lg' || screen==='md' || screen==='xl' || screen==='sm'" style="padding: 0px 5px 25px !important;">
      <div class="float-left">
        <label *ngFor="let pt of pastTargetScorePair">
          <span *ngIf="pt.score > 0 && systemService.systemLanguage=='de'" style= "color:green; padding-right:3px; ">{{pt.target[0].toUpperCase() + pt.target.slice(1)}}</span>
          <span *ngIf="pt.score > 0 && systemService.systemLanguage=='en'" style= "color:green; padding-right:3px; ">{{pt.target}}</span>
          <span *ngIf="pt.score === 0 && systemService.systemLanguage=='de'" style= "color:red; padding-right:3px; ">{{pt.target[0].toUpperCase() + pt.target.slice(1)}}</span>
          <span *ngIf="pt.score === 0 && systemService.systemLanguage=='en'" style= "color:red; padding-right:3px; ">{{pt.target}}</span>
        </label>
      </div>
      <div class="float-right">
        <label *ngFor="let wa of wrongAssumption">
           <span style= "color:red;padding-left:3px;">{{wa}}</span>
        </label>
      </div>
    </div>

<mat-card-content style="padding: 1px !important;" >
  <mat-grid-list  [cols]="breakpoint" rowHeight="9:{{breakpoint}}" role="list">
    <mat-grid-tile *ngFor="let d of roundContext" (click)="contextClick(contextTemplate, $event, d.context); isTarget=true">
      <label style="{{fontStyle}}" [attr.id]="'obj_' + d.context"></label>
    </mat-grid-tile>
  </mat-grid-list>

  <div class="{{inputGroupStyle}}">
    <div class="input-group-prepend">
      <div *ngIf="cue!=''"
           style="{{fontStyle}}"
           class="input-group-text">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].extraCue}}: {{cue}}</div>
    </div>
    <input style="{{fontStyle}} height: calc(2em + 0.5rem + 5px);" type="text"
          class="form-control" placeholder="{{targetCue}}"
          aria-describedby="button-addon4"
          (keyup.enter)="guessClick(contextTemplate)"
          (keyup.arrowright)="skipClick(contextTemplate)"
          id="targetInput">
    <div class="input-group-append" id="button-addon4">
      <button style="background-color:#556B2F; color:#000000; min-width: 15px !important;"
              class="btn btn-outline-secondary"
              (click)="guessClick(contextTemplate)"
              mat-stroked-button>
        {{systemService.systemLanguage == 'en' ? systemService.guessEnButtons[systemService.screen] : systemService.guessDeButtons[systemService.screen]}}
        <mat-icon>{{guessIcon}}</mat-icon>
      </button>
      <button style="background-color:#B22222; color:#000000; min-width: 15px !important;"
              class="btn btn-outline-secondary"
              (click)="skipClick(contextTemplate)"
              mat-stroked-button>
        {{systemService.systemLanguage == 'en' ? systemService.skipEnButtons[systemService.screen]:systemService.skipDeButtons[systemService.screen]}}
        <mat-icon>{{skipIcon}}</mat-icon>
      </button>
    </div>
  </div>
  </mat-card-content>
</div>
</ng-template>

<ng-template #elseBlock>
  <div style="{{cardStyle}} padding: 5px !important;">
    <mat-card-header>
      <div mat-card-avatar class="example-header-image"></div>
      <mat-card-title style="{{fontStyle}}">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameOverLabel}}</mat-card-title>
    </mat-card-header>

  <table mat-table [dataSource]="dataSource"class="mat-elevation-z8">
      <!-- Position Column -->
      <ng-container matColumnDef="target">
        <th mat-header-cell *matHeaderCellDef>{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameTarget}} </th>
        <td mat-cell *matCellDef="let element"> {{element.target}} </td>
        <td mat-footer-cell *matFooterCellDef style="color:#B22222"> {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].totalScore}} </td>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="score">
        <th mat-header-cell  *matHeaderCellDef> {{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameScore}} </th>
        <td mat-cell  *matCellDef="let element"> {{element.score}} </td>
        <td mat-footer-cell *matFooterCellDef style="{{fontStyle}}">{{getTotalScore()}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
    <br>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
      <!--<mat-button-toggle (click)="gameAgainClick()">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].gameAgain}}</mat-button-toggle>-->
      <mat-button-toggle (click)="decisionAgainClick()">{{systemService.screen2Language[systemService.screen][systemService.systemLanguage].decisionAgain}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</ng-template>
