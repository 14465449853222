export class ExportData{
  _id : string;
  gameCode : string;
  player : any;
  decision : any;
  game : any[];
  actions : any[];
  date : string;
  totalScore : string;
}

export class Project{
  name:string;
  language:string;
  class:string;
  case:string;
  code: String;
  date: String;
  isActive:Boolean;
}

export class Export{
  id:string;
  data:ExportData;
}

export class FileObject{
  name:string;
  type:string;
}

export class TargetPair{
  target:string;
  score:number;
}

export class OnlineReseacher {
  _id: string;
  name: String;
  code: String;
}

export class PlayerRegisteration {
  name: string;
  gender: String;
  age: String;
  nativeLanguage:string;
  date:string;
}

export class OnlinePlayer {
  _id:string;
  age: string;
  gender: string;
  name: string;
  nativeLanguage: string;
  date:string;
}

export class DataPoint {
   pairs: [];
}

export class SystemLanguage{
  en: string;
  de: string;
  code: string;
}

export class GermanLan{
  id:number;
  name:string;
  alpha2:string;
  alpha3: string;
}

export class GameSettings{
  gameCode:string;
  data: string;
  researcher:any;
  name:string;
  targetOrder:string;
  contextOrder:string;
  contextNumber:string;
  hintOrder:string;
  description:string;
  date:string;
  isActive:Boolean;
}

export class UpdateGameSettings{
  _id:string;
  gameCode:string;
  data: string;
  researcher:any;
  name:string;
  targetOrder:string;
  contextOrder:string;
  contextNumber:string;
  hintOrder:string;
  description:string;
  date:string;
  isActive:Boolean;
}

export class Data{
  target:string;
  targetCue:string;
  contextCue: Pair;
  context: Pair[];
}

export class RawData{
  target:string;
  context: Pair[];
}

export class Pair{
  context:string;
  score:number;
}

export class Diff{
  m:string;
  e:string;
}

export class Target{
  target:string;
  score:number;
}

export interface DialogData {
  target: string;
  icon: string;
  screen:string;
}

export interface ConfirmationDialogData {
  confirmationObject: string;
}

export class Decision {
  lang: string;
  class: string;
  targetNum: string;
  difficulty: string;
}

export class User {
  userName: String;
  age:number;
  gender:String;
  nativeLang: String;
}

export class Researcher {
  name: String;
  code: String;
  date: String;
  isActive:Boolean;
}

export class LoginResearcher {
  name: String;
  code: String;
}


export class OnlineUser {
  _id: string;
  userName: string;
  age: string;
  gender: string;
  nativeLang: string;
  __v: string;
}


export class Action{
  action: string;
  token: string;
  time: string;
  extraContextCue: string;
  targetCue: string;
}

export class NativeLan{
  English: string;
  alpha2: string;
}

export interface Bank {
  id: string;
  name: string;
}

export interface BankGroup {
  name: string;
  banks: Bank[];
}
