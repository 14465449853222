import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FileUploadModule } from 'ng2-file-upload';
import { MaterialModule } from './material.module';
import { AngularWebStorageModule } from 'angular-web-storage';
import { AuthInterceptor } from './auth.interceptor';
import { MatSortModule } from '@angular/material/sort';

import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { ResearcherComponent } from './researcher/researcher.component';
import { ResearcherLoginComponent } from './researcher-login/researcher-login.component';
import { PlayerComponent } from './player/player.component';
import { DecisionComponent } from './decision/decision.component';
import { GameComponent } from './game/game.component';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    ResearcherComponent,
    ResearcherLoginComponent,
    PlayerComponent,
    DecisionComponent,
    GameComponent,
    ProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    LayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    FileUploadModule,
    HttpClientModule,
    AngularWebStorageModule,
    MatSortModule
  ],
  providers: [HttpClientModule,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
